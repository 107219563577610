import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import xIcon from "../../assets/ic-appdown-xmark-w-22.svg";
import "./app-landing-container.scss";
import { getAnalytics, logEvent } from "firebase/analytics";


const AppLandingContainer = (props: { line2: string }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(true);
  const analytics = getAnalytics();


  const routeDownload = () => {
    logEvent(analytics, 'route_download', { name: 'app_landing_container'});
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };
  const onClickXButton = () => {
    setShow(false);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {" "}
      {show ? (
        <div className="app-landing-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={routeDownload}
          >
            {" "}
            <div className="landing-text-section">
              <div className="first-line">
                <p className="landing-text-1">원더러스트</p>{" "}
                <p className="landing-text-2"> 앱에서 </p>
              </div>
              <p className="landing-text-2">{props.line2}</p>
            </div>
            <div className="to-app-button">앱으로 보기</div>
          </div>

          <div className="x-button">
            <img src={xIcon} onClick={onClickXButton} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AppLandingContainer;
