import {axios} from "../../lib/axios";

export const getItineraries = async (userId: string, itineraryObjectId?: string) => {
    const params = {id: itineraryObjectId}
    const response = await axios.get('/v1/users/' + userId + '/itineraries', {params});
    return [response.data.count, response.data.itineraries];
};

export const getItinerary = async (userId: string, itineraryId: string) => {
    const response = await axios.get('/v1/users/' + userId + '/itineraries/' + itineraryId);
    return response.data.itinerary;
};
