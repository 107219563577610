import { axios } from "../../lib/axios";

export type GetPostsQueryParameters = {
  params?: {
    type?: string;
    updatedAt?: string;
    cityId?: string;
    countryId?: string;
    regionId?: string;
    startDate?: string;
    endDate?: string;
  };
};

export const getPosts = async ({ params }: GetPostsQueryParameters) => {
  const response = await axios.get("/v1.1/posts", { params });
  return response.data.posts;
};

export const getPopularPosts = async ({ params }: GetPostsQueryParameters) => {
  const response = await axios.get("v1/recommend/popular-posts", { params });
  return response.data.posts;
}

export const getPostsOfUser = async (userId: string, updatedAt?: string) => {
  const params = {
    type: "GATHERING",
    updatedAt: updatedAt,
  };
  const response = await axios.get("/v1/users/" + userId + "/posts", {
    params,
  });
  return response.data.posts;
};
