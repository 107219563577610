import { axios } from "../../lib/axios";

export const getLatestPosts = async () => {
  const response = await axios.get("/v1/recommend/latest-posts");
  return response.data.posts;
};

export const getRelatedPosts = async (
  countryId: string,
  startDate: string,
  endDate: string
) => {
  const params = {
    countryId: countryId,
    startDate: startDate,
    endDate: endDate,
  };
  const response = await axios.get("/v1/recommend/related-posts", { params });
  return response.data.posts;
};

export const getRecommendedUsers = async () => {
  const response = await axios.get("/v1/recommend/recommended-users");
  return response.data.users;
};
