import React, { useState } from "react";
import ItineraryCard from "../../../components/itinerary-card/itinerary-card";
import Posts from "../../../components/posts/posts";
import noInstagramIcon from "../../../assets/ic-instagram-off-line-g-22.svg";
import hasInstagramIcon from "../../../assets/ic-instagram-on-line-g-22.svg";
import linkImage from "../../../assets/ic-link-b-21.svg";
import chatIcon from "../../../assets/ic-profile-chat-18.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, CircularProgress, Divider } from "@mui/material";
import emptyProfileImage from "../../../assets/empty-profile.svg";
import EmptyItinerary from "../../itinerary-card/empty-itinerary";
import { getAnalytics, logEvent } from "firebase/analytics";

interface RecommendUserProps {
  posts: any[];
  user: any;
  needToLoadMorePosts: Boolean;
  users: any[];
  fetchMorePosts: () => any;
  onClickItineraryTab: () => any;
  onClickPostTab: () => any;
  currentTabNumber: any;
}

const MobileWebDetailProfileContainer: React.FC<RecommendUserProps> = ({
  posts,
  user,
  needToLoadMorePosts,
  users,
  fetchMorePosts,
  onClickItineraryTab,
  onClickPostTab,
  currentTabNumber,
}) => {
  const routeDownload = () => {
    logEvent(analytics, "route_download", { name: "profile_chat_request" });
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };

  const analytics = getAnalytics();

  const copyToClipboard = () => {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  return (
    <div>
      <div className="post" style={{ width: 360 }}>
        <div className="detail-profile">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              className="profile-image"
              src={
                user.images && user.images.length > 0
                  ? user.images[0]
                  : emptyProfileImage
              }
            />

            {/* {user.isPrivateInstagramId ? (
              <div className="instagram-section">
                <img src={hasInstagramIcon}></img>
                <div className="instagram-id">아이디 비공개</div>
              </div>
            ) : (
              <div className="instagram-section">
                <img src={hasInstagramIcon}></img>
                <div className="instagram-id">{user.instagramId}</div>
              </div>
            )} */}
            <div>
              <div className="user-name"> {user.name} </div>
              <div style={{ display: "flex", marginTop: "20px" }}>
                <Card
                  className="mobile-chat-button"
                  sx={{
                    // backgroundColor: "rgba(244, 245, 245, 0.5)",
                    backgroundColor: "rgba(53, 197, 245, 0.12)",
                    color: "rgb(87, 103, 109)",
                    boxShadow: "none",
                    border: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                    borderRadius: "3px",
                    gap: "5px",
                    cursor: "pointer",
                    height: "33px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    routeDownload();
                  }}
                >
                  <img src={chatIcon} />
                  <div
                    style={{
                      fontFamily: "Spoqa Han Sans Neo",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#35c5f5",
                    }}
                  >
                    채팅요청
                  </div>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "5px",
                    borderRadius: "3px",
                    border: "solid 1px #e8eced",
                    backgroundColor: "rgba(244, 245, 245, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    copyToClipboard();
                    alert("링크가 복사되었습니다.");
                  }}
                >
                  {" "}
                  <img
                    src={linkImage}
                    style={{ marginRight: "3px" }}
                  ></img>{" "}
                  <div
                    style={{
                      fontFamily: "Spoqa Han Sans Neo",
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#57676d",
                    }}
                  >
                    URL
                  </div>{" "}
                </Card>
              </div>
            </div>
          </div>
          <div>
            {user.bio ? (
              <div className="mobile-user-bio">{user.bio}</div>
            ) : (
              <div className="mobile-user-bio">아직 자기소개가 없어요</div>
            )}
          </div>
        </div>
        <div className="profile-divider" />
        {/* {currentTabNumber == 0 && (
          <div className="tab-section">
            <div
              className="selected-tab-container"
              onClick={onClickItineraryTab}
            >
              여행
              <div className="selected-blue-line" />
            </div>
            <div className="tab-container" onClick={onClickPostTab}>
              활동
            </div>
          </div>
        )}
        {currentTabNumber == 1 && (
          <div className="tab-section">
            <div className="tab-container" onClick={onClickItineraryTab}>
              여행
            </div>
            <div className="selected-tab-container" onClick={onClickPostTab}>
              활동
              <div className="selected-blue-line" />
            </div>
          </div>
        )} */}

        <div className="itineraries-section" style={{ marginTop: "22px" }}>
          {currentTabNumber == 0 && <ItineraryCard></ItineraryCard>}
          {currentTabNumber === 1 ? (
            <InfiniteScroll
              dataLength={posts?.length ?? 0}
              next={fetchMorePosts}
              hasMore={needToLoadMorePosts && currentTabNumber == 1}
              loader={
                <CircularProgress
                  sx={{
                    color: "#35C5f5",
                    marginLeft: "160px",
                    marginTop: "30px",
                  }}
                />
              }
              style={{ overflow: "hidden" }}
            >
              {posts.length > 0 && currentTabNumber == 1 ? (
                <Posts posts={posts!} users={users!}></Posts>
              ) : (
                <EmptyItinerary text="등록된 모집글이 없습니다."></EmptyItinerary>
              )}
            </InfiniteScroll>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileWebDetailProfileContainer;
