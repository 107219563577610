import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailPostData,
  getRecommendedData,
} from "../../usecases/get-detail-post-use-case";
import { MobileWeb, Web } from "../../lib/react-responsive";
import RecommendContainer from "../../components/recommend-container/recommend-container";
import RecommendUserContainer from "../../components/recommend-container/recommend-user-container";
import WebDetailPostContainer from "../../components/detail-post-container/web/web-detail-post-container";
import MobileWebDetailPostContainer from "../../components/detail-post-container/mobile-web/mobile-web-post-container";
import "./detail-post.scss";
import AppLandingContainer from "../../components/app-landing-container/app-landing-container";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAnalytics, logEvent } from "firebase/analytics";
import User from "../../data/models/user";


const DetailPost: React.FC = () => {
  const navigate = useNavigate();
  const postId = useParams().postId;
  const [post, setPost] = useState<any>();
  const [user, setUser] = useState<any>();
  const [latestPosts, setLatestPosts] = useState<any>([]);
  const [relatedPosts, setRelatedPosts] = useState<any>([]);
  const [recommendedUsers, setRecommendedUsers] = useState<any>([]);
  const [deletedUser, setDeletedUser] = useState<boolean>(false);

  const analytics = getAnalytics();

  useEffect(() => {
    async function fetchData() {
      const data = await getDetailPostData(postId!);
      // window.scrollTo(0, 0);
      setPost(data[0]);
      setUser(data[1]);
      window.scrollTo(0, 0);
      if (data[0] === null) {
        navigate("/posts/not-found");
      }
      if (data[1].active === "DELETED") {
        setDeletedUser(true);
      }
    }

    if (postId) {
      fetchData();
      logEvent(analytics, 'web_screen_view', {
        firebase_screen: 'web_detail_post_page',
        firebase_screen_class: "WebDetailPostPage"
      });
    }
  }, [postId]);

  useEffect(() => {
    async function fetchRecommendation() {
      const data = await getRecommendedData(
        post.city.country.countryId,
        post.startDate,
        post.endDate
      );
      setLatestPosts(data[0]);
      setRelatedPosts(data[1]);
      setRecommendedUsers(data[2]);
    }

    if (post) {
      fetchRecommendation();
    }
  }, [post]);

  function appendParticipants(
    addedParticipants: User[] = [],
    existingParticipants: User[] = [],
  ) {
    var participants = [...addedParticipants, ...existingParticipants];
    participants = [
      ...new Map(participants.map((item) => [item["userId"], item])).values(),
    ];

    return participants;
  }

  return (
    <HelmetProvider>
    <div className="detail-post-wrapper">
            {post ? (      <Helmet>
      <meta property="og:url" content="https://wanerlust.marco-corp.com/" />
    <meta property="og:title" content={"[동행모집" + post.title} />
    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/marco-d2250.appspot.com/o/meta.png?alt=media&token=92ba9f7b-160a-4926-8522-f8417e330cda" />
    <meta property="og:description" content={post.contents} />
    <meta property="og:image:width" content="1024" />
    <meta property="og:image:height" content="500" />
      </Helmet>) : (<></>)}
      <Web>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          {post ? (
            <WebDetailPostContainer
              post={post}
              user={user}
              deleted={deletedUser}
              participants={appendParticipants(post.addedParticipants, post.existingParticipants)}
            />
          ) : (
            <></>
          )}
          <RecommendContainer
            latestPosts={latestPosts}
            relatedPosts={relatedPosts}
          />
        </div>
        <div
          className="user-recommandations"
          style={{ width: "941px", height: "235px" }}
        >
          {" "}
          <RecommendUserContainer recommendedUsers={recommendedUsers} />
        </div>
      </Web>
      <MobileWeb>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          <div className="mobile-post" style={{ width: 360 }}>
            {" "}
            {post ? (
              <MobileWebDetailPostContainer
                post={post}
                user={user}
                deleted={deletedUser}
                participants={appendParticipants(post.addedParticipants, post.existingParticipants)}
              />
            ) : (
              <></>
            )}
            <RecommendContainer
              latestPosts={latestPosts}
              relatedPosts={relatedPosts}
            />
            <div
              className="user-recommandations"
              style={{ width: "360px", height: "235px" }}
            >
              {" "}
              <RecommendUserContainer recommendedUsers={recommendedUsers} />
            </div>
          </div>
        </div>
        <div className="app-landing">
          <AppLandingContainer line2="동행을 요청해보세요!"></AppLandingContainer>
        </div>
      </MobileWeb>
    </div>
    </HelmetProvider>

  );
};

export default DetailPost;
