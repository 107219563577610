import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import blueDot from "../../assets/ic-web-circle-p-5.svg";
import emptyProfileImage from "../../assets/empty-profile.svg";
import { MobileWeb, Web } from "../../lib/react-responsive";

interface RecommendUserProps {
  recommendedUsers: any[];
}

const RecommendUserContainer: React.FC<RecommendUserProps> = ({
  recommendedUsers,
}) => {
  const navigate = useNavigate();

  const routeDetailPost = (postId: string) => {
    navigate("/posts/" + postId);
  };

  const routePostList = () => {
    navigate("/");
  };

  const routeDetailUser = (userId: string) => {
    navigate("/users/" + userId);
  };

  return (
    <div>
      <Web>
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "20px",
            fontWeight: "500",
            color: "#57676d",
          }}
        >
          다른 여행 친구 둘러보기
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          {recommendedUsers.length > 0 ? (
            Array.from(recommendedUsers).map((user: any) => (
              <Card
                variant="outlined"
                sx={{
                  width: "177px",
                  height: "190px",
                  boxShadow: "none",
                  borderColor: "#e8eced",
                  borderWidth: "1px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "11px",
                }}
                onClick={() => {
                  routeDetailUser(user.userId);
                }}
              >
                <img
                  src={
                    user.images && user.images.length > 0
                      ? user.images[0]
                      : emptyProfileImage
                  }
                  style={{
                    width: "76px",
                    height: "76px",
                    borderRadius: "38px",
                    marginTop: "36px",
                    marginBottom: "20px",
                  }}
                />
                <div
                  style={{
                    fontFamily: "Spoqa Han Sans Neo",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#3b3f41",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    padding: "0 10px",
                    textAlign: "center",
                  }}
                >
                  {user.name}
                </div>
              </Card>
            ))
          ) : (
            <></>
          )}
        </div>
      </Web>
      <MobileWeb>
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "18px",
            fontWeight: "500",
            color: "black",
            marginBottom: "15px",
            marginTop: "30px",
          }}
        >
          다른 여행 친구 둘러보기
        </div>
        <div
          style={{
            // marginTop: "20px",
            // display: "grid",
            // gridTemplateColumns: "repeat(2, 1fr)",
            // gridGap: "10px",
            // gridAutoRows: "minmax(100px, auto)",
            width: "100%",
            overflow: "auto",
            display: "flex",
          }}
        >
          {recommendedUsers.length > 0 ? (
            Array.from(recommendedUsers).map((user: any) => (
              <div
                style={{ width: "150px", height: "170px", marginRight: "10px" }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    width: "150px",
                    height: "170px",
                    boxShadow: "none",
                    borderColor: "#e8eced",
                    borderWidth: "1px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: "11px",
                    justifyContent: "center",
                    overflow: "auto",
                  }}
                  onClick={() => {
                    routeDetailUser(user.userId);
                  }}
                >
                  <img
                    src={
                      user.images && user.images.length > 0
                        ? user.images[0]
                        : emptyProfileImage
                    }
                    style={{
                      width: "76px",
                      height: "76px",
                      borderRadius: "38px",
                      marginTop: "0px",
                      marginBottom: "10px",
                    }}
                  />
                  <div
                    style={{
                      fontFamily: "Spoqa Han Sans Neo",
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#3b3f41",
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                      padding: "0 10px",
                      textAlign: "center",
                    }}
                  >
                    {user.name}
                  </div>
                </Card>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </MobileWeb>
    </div>
  );
};

export default RecommendUserContainer;
