import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import "./post.scss";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import viewCountImage from "../../assets/ic-home-viewcount-line-17.svg";
import requestCountImage from "../../assets/ic-home-ask-line-17.svg";
import cityBarImage from "../../assets/ic-city-rec-154.svg";
import useEventBus from "../../event-bus";
import { EventBusMessages } from "../../event-bus/event-bus-message";
import UserModel from "../../data/models/user";
import emptyProfileImage from "../../assets/empty-profile.svg";

interface PostProps {
  postId: string;
  title: string;
  contents: string;
  city: string;
  date: string;
  viewCount: number;
  requestCount: number;
  flag: string;
  author: UserModel;
  participants: UserModel[];
}

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
  height: 183;
  width: 360;
  border: none;
  boxShadow: none;
`);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      // width: "25ch",
      width: "100vw",
    },
  },
  card: {
    border: "none",
    boxShadow: "none",
  },
  media: {
    paddingTop: "56.25%",
  },
  content: {
    textAlign: "left",
    // padding: theme.spacing.arguments * 3,
  },
  title: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 400,
    fontSize: 16,
  },
  contents: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 300,
    lineHeight: 1.6,
    maxHeight: 45,
    paddingBottom: 12,
    fontSize: 14,
  },
  city: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 300,
    paddingBottom: 20,
    fontSize: 14,
  },
  userName: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 300,
    fontSize: 14,
  },
  avatar: {
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      // marginLeft: theme.spacing,
    },
  },
}));

const MobilePost: React.FC<PostProps> = ({
  postId,
  title,
  contents,
  city,
  date,
  flag,
  author,
  participants,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [users, setUsers] = useState<string>();
  const eventBus = useEventBus<EventBusMessages>();

  const routeDetailPost = (postId: string) => {
    console.log(window.pageYOffset);
    eventBus.publish({
      topic: "ScrollPosition",
      payload: { scrollPosition: window.pageYOffset },
    });
    sessionStorage.setItem(
      "scrollPosition",
      JSON.stringify(window.pageYOffset)
    );
    navigate("/posts/" + postId);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Card
        className={classes.card}
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          cursor: "pointer",
          height: 143 + 43,
          width: 360,
          borderRadius: "8px",
        }}
        onClick={() => {
          routeDetailPost(postId);
        }}
      >
        <CardContent sx={{ width: 360 }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
            gutterBottom
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {author.active == "DELETED" ? (
                <img
                  src={emptyProfileImage}
                  style={{
                    marginRight: "11px",
                    height: "36px",
                    borderRadius: "18px",
                  }}
                />
              ) : (
                <img
                  src={author.images[0]}
                  style={{
                    marginRight: "11px",
                    height: "36px",
                    borderRadius: "18px",
                  }}
                />
              )}
              <div>
                <div
                  style={{
                    fontFamily: "Spoqa Han Sans Neo",
                    fontWeight: 500,
                    fontSize: 12,
                  }}
                >
                  {author.name}
                </div>
                <div
                  style={{
                    maxWidth:
                      participants.length > 2
                        ? 180
                        : participants.length == 2
                        ? 200
                        : 1000,
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    fontFamily: "Spoqa Han Sans Neo",
                    fontWeight: 300,
                    fontSize: 12,
                    color: "#9da5a8",
                  }}
                >
                  {author.bio}
                </div>
              </div>
            </div>

            {participants.length > 1 ? (
              <div style={{ right: "0", position: "relative", display: "flex" }}>
                {participants.map((participant) => (
                  <div>
                    {participant.active === "DELETED" ? (
                      <img
                        src={emptyProfileImage}
                        style={{
                          marginLeft: "-15px",
                          height: "38px",
                          borderRadius: "19px",
                          borderColor: "red",
                          border: "3px solid white",
                        }}
                      />
                    ) : (
                      <img
                        src={participant.images[0]}
                        style={{
                          marginLeft: "-15px",
                          height: "38px",
                          borderRadius: "19px",
                          borderColor: "red",
                          border: "3px solid white",
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </Typography>

          <Typography
            className={classes.title}
            gutterBottom
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.contents}
            variant="body2"
            color="textSecondary"
            component="p"
            gutterBottom
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              marginBottom: 2,
            }}
          >
            {contents}
          </Typography>
          <Typography
            className={classes.userName}
            variant="body2"
            component="p"
            display="flex"
            flex="row"
            sx={{
              fontFamily: "Spoqa Han Sans Neo",
              fontWeight: "300",
              fontSize: "15px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <img
                src={flag}
                style={{
                  marginRight: "11px",
                  height: "20px",
                  borderRadius: "10px",
                  border: "1px solid #c6ced0",
                }}
              />
              <div>{city}ㆍ</div> <div>{date}</div>
            </div>
            {/* <div>
  <img src={viewCountImage} style={{ marginRight: "2px" }} />{" "}
  {viewCount}
</div> */}
          </Typography>
        </CardContent>
      </Card>

      {/* <Divider
        sx={{
          color: "#e9e9e9",
          overflow: "visible",
          width: "100vw",
          borderBottomWidth: "6px",
        }}
      /> */}
    </div>
  );
};

export default MobilePost;

{
  /* <Typography
className={classes.title}
gutterBottom
sx={{
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}}
>
{title}
</Typography>
<Typography
className={classes.contents}
variant="body2"
color="textSecondary"
component="p"
gutterBottom
sx={{
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  marginBottom: 2,
}}
>
{contents}
</Typography>
<Typography
className={classes.city}
variant="body2"
component="p"
display="flex"
flexDirection="row"
>
<img src={cityBarImage} style={{ marginRight: "11px" }} />
<div>{city}ㆍ</div> <div className="date">{date}</div>
</Typography>
<Typography
className={classes.userName}
variant="body2"
component="p"
display="flex"
width="328px"
flex="row"
sx={{
  color: "#9da5a8",
  fontFamily: "Spoqa Han Sans Neo",
  fontWeight: "300",
  fontSize: "15px",
  justifyContent: "space-between",
}}
>
<div>{author.name}</div>
<div>
  <img src={viewCountImage} style={{ marginRight: "2px" }} />{" "}
  {viewCount}
</div>
</Typography> */
}
