import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { availableSearchCity } from "../../../../data/services/cities-api-service";
import {
  EventBusMessages,
  CityFilterMessage,
} from "../../../../event-bus/event-bus-message";
import useEventBus from "../../../../event-bus";

interface City {
  _id: string;
  cityId: string;
  ko: string;
  en: string;
}
interface Country {
  _id: string;
  countryId: string;
  ko: string;
  en: string;
  flag: string;
}
interface Region {
  _id: string;
  regionId: string;
  ko: string;
  en: string;
  flag: string;
}

type ChildProps = {
  // cityName: string
  changeCityName: (city: string) => void;
  // −−−−−−−−−−−−−−−−−−−−−−−−−−−−−−−^^^^^^^^^^^^^^^
};

const CityFilter = (props: {isModal: boolean}) => {
  const eventBus = useEventBus<EventBusMessages>();
  const navigate = useNavigate();
  const [cities, setCities] = useState<City[]>();
  const [countries, setCountries] = useState<Country[]>();
  const [regions, setRegions] = useState<Region[]>();
  const [cityName, setCityName] = useState<string>("도시 선택");

  useEffect(() => {
    async function fetchCities() {
      const data = await availableSearchCity();
      setCities(data.cities);
      setCountries(data.countries);
      setRegions(data.regions);
    }
    fetchCities();
  }, []);

  function onClickCity(cityName: string, cityId: string, cityType: any) {
    eventBus.publish({
      topic: "CityFilter",
      payload: { city: cityId, cityType: cityType, cityName: cityName },
    });
  }

  return (
    <Card
      variant="outlined"
      className="city-selection"
      sx={{
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        flexDirection: "column",
        height: "186px",
        width: props.isModal ? ("427px") : ("293px"),
        zIndex: "3",
        borderRadius: "12px",
        marginTop: "5px",
        color: "#3b3f41",
        fontFamily: "Spoqa Han Sans Neo",
        fontWeight: "400",
        fontSize: "16",
        overflow: "auto",
        boxShadow:
          "0px 7px 8px -4px rgb(0 0 0 / 15%), 0px 12px 17px 2px rgb(0 0 0 / 0%), 0px 5px 22px 4px rgb(0 0 0 / 6%)",
      }}
    >
      {cities ?? [].length > 0 ? (
        cities!.map((city) => (
          <CardContent
            sx={{ cursor: "pointer" }}
            onClick={() =>
              onClickCity(city.ko, city.cityId, { cityId: city.cityId })
            }
          >
            {city.ko}
          </CardContent>
        ))
      ) : (
        <p></p>
      )}
      {countries ?? [].length > 0 ? (
        countries!.map((country) => (
          <CardContent
            sx={{ cursor: "pointer" }}
            onClick={() =>
              onClickCity(country.ko, country.countryId, {
                countryId: country.countryId,
              })
            }
          >
            {" "}
            <img
              className="flag"
              src={country.flag}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "17px",
                borderRadius: "16px",
              }}
            />
            {country.ko}
          </CardContent>
        ))
      ) : (
        <p></p>
      )}
      {regions ?? [].length > 0 ? (
        regions!.map((region) => (
          <CardContent
            sx={{ cursor: "pointer" }}
            onClick={() =>
              onClickCity(region.ko, region.regionId, {
                regionId: region.regionId,
              })
            }
          >
            {" "}
            <img
              className="flag"
              src={region.flag}
              style={{
                width: "32px",
                height: "32px",
                marginRight: "17px",
                borderRadius: "16px",
              }}
            />
            {region.ko}
          </CardContent>
        ))
      ) : (
        <p></p>
      )}
    </Card>
  );
};

export default CityFilter;
