import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import viewCountImage from "../../../assets/View_Count_Icon.svg";
import shareIconImage from "../../../assets/Share_Icon.svg";
import calendarImage from "../../../assets/ic-plan-date-gray-14.svg";
import emptyProfileImage from "../../../assets/empty-profile.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./mobile-web-post-container.scss";
import User from "../../../data/models/user";

interface RecommendUserProps {
  post: any;
  user: any;
  deleted: boolean;
  participants: User[];
}

const MobileWebDetailPostContainer: React.FC<RecommendUserProps> = ({
  post,
  user,
  deleted,
  participants,
}) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const routeDetailUser = (userId: string) => {
    navigate("/users/" + userId);
  };

  const routeDownload = () => {
    logEvent(analytics, "route_download", { name: "detail_post_request" });
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };

  function trimDate(date: string) {
    let result: string = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  const copyToClipboard = () => {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  return (
    <div style={{ marginTop: "32px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginBottom: "20px",
        }}
        onClick={() => {
          routeDetailUser(user.userId);
        }}
      >
        <img
          src={
            !deleted && user.images && user.images.length > 0
              ? user.images[0]
              : emptyProfileImage
          }
          style={{
            height: "56px",
            width: "56px",
            borderRadius: "28px",
            marginRight: "10px",
          }}
        />
        {/* <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          {user.name}
        </div> */}

        <div>
          <div
            style={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {user.name}
          </div>
          <div
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              fontFamily: "Spoqa Han Sans Neo",
              fontWeight: 300,
              fontSize: 15,
              color: "#9da5a8",
            }}
          >
            {user.bio}
          </div>
        </div>
      </div>
      <div
        className="post-date"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="mobile-detail-post-city-container">
          {" "}
          <img
            src={post.city.country.flag}
            style={{
              marginLeft: "0px",
              marginRight: "4px",
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              border: "1px solid #c6ced0",
            }}
          />
          {post.city.country.ko} {","} {post.city.ko}{" "}
        </div>
        <div
          className="mobile-detail-post-city-container"
          style={{ marginLeft: "8px" }}
        >
          <img
            src={calendarImage}
            style={{
              marginRight: "4px",
              width: "20px",
              height: "20px",
            }}
          />
          {trimDate(post.startDate)} - {trimDate(post.endDate)}
        </div>
      </div>

      <div className="post-title">{post.title}</div>
      {/* <div
        className="post-user"
        style={{ display: "flex", alignItems: "center" }}
      > */}
      {/* <Card
          variant="outlined"
          sx={{
            padding: "5px",
            borderRadius: "3px",
            border: "solid 1px #e8eced",
            backgroundColor: "rgba(244, 245, 245, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            right: "0%",
            cursor: "pointer",
          }}
          onClick={() => {
            copyToClipboard();
            alert("링크가 복사되었습니다.");
          }}
        >
          {" "}
          <img src={linkImage} style={{ marginRight: "3px" }}></img>{" "}
          <div
            style={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "13px",
              fontWeight: "500",
              color: "#57676d",
            }}
          >
            URL
          </div>{" "}
        </Card> */}
      {/* </div> */}
      {/* <Divider sx={{ color: "#e8eced" }} /> */}
      <div className="post-content">{post.contents}</div>
      {participants.length > 0 ? (
        <div className="mobile-detail-post-participants-header">
          같이 여행해요
        </div>
      ) : (
        <></>
      )}

      <div className="mobile-detail-post-participants" >
        {participants.length > 0 ? (
          <div style={{height: "40px", width:"100%", overflowY: "scroll", display: "flex", marginBottom: "16px"}}>
            {participants.map((participant) => (
              <img
                src={participant.images[0]}
                style={{
                  height: "40px",
                  borderRadius: "20px",
                  marginRight: "10px",
                }}
                onClick={() => {
                  routeDetailUser(participant.userId);
                }}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mobile-detail-post-count-container">
        <div className="mobile-detail-post-counter">
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <img
              src={viewCountImage}
              style={{ height: "24px", marginRight: "8px" }}
            />{" "}
            {post.viewCount}{" "}
          </div>
        </div>

        <div
          className="mobile-detail-post-counter"
          style={{ cursor: "pointer" }}
          onClick={() => {
            copyToClipboard();
            alert("링크가 복사되었습니다.");
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <img
              src={shareIconImage}
              style={{ height: "24px", marginRight: "8px" }}
            />{" "}
            {"공유하기"}{" "}
          </div>
        </div>
      </div>

      <Divider sx={{ color: "#e8eced" }} />
      <Card
        sx={{
          width: "360px",
          height: "50px",
          border: "none",
          backgroundColor: "#35c5f5",
          alignContent: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "none",
          fontFamily: "Spoqa Han Sans Neo",
          fontSize: "15px",
          fontWeight: "400",
          color: "#ffffff",
          borderRadius: "6px",
          marginTop: "19px",
          cursor: "pointer",
        }}
        onClick={() => {
          routeDownload();
        }}
      >
        동행 요청하기
      </Card>

      <Divider className="divider" sx={{ marginBottom: "20px" }} />
    </div>
  );
};

export default MobileWebDetailPostContainer;
