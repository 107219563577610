import React, { useEffect, useState } from "react";
import { Card } from "@mui/material";
import CityFilter from "../filter/citySelection/city-filter";
import { availableSearchCity } from "../../../data/services/cities-api-service";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import DayPickerRangeControllerWrapper from "../../calendar/DayPickerRangeControllerWrapper";
import "./mobile-filter.scss";
import locationImage from "../../../assets/ic-location-pin-g-14.svg";
import calendarImage from "../../../assets/ic-filter-calendar-g-14.svg";
import whiteLocationImage from "../../../assets/ic-filter-location-pin-w-14.svg";
import whiteCalendarImage from "../../../assets/ic-filter-calendar-w-14.svg";
import refreshIcon from "../../../assets/ic-filter-rotate-circle-g-18.svg";
import {
  EventBusMessages,
  DateFilterMessage,
  CityFilterMessage,
} from "../../../event-bus/event-bus-message";

import useEventBus from "../../../event-bus";
import MobileCityFilter from "./mobile-city-cell";
import MobileDateFilter from "./mobile-date-filter";
import { color, padding } from "@mui/system";

interface City {
  _id: string;
  cityId: string;
  ko: string;
  en: string;
}
interface Country {
  _id: string;
  countryId: string;
  ko: string;
  en: string;
  flag: string;
}
interface Region {
  _id: string;
  regionId: string;
  ko: string;
  en: string;
  flag: string;
}

const MobileFilter = () => {
  const eventBus = useEventBus<EventBusMessages>();
  const [showCitySeleciton, setShowCitySeleciton] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [cityName, setCityName] = useState("도시 선택");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [citySelected, setCitySelected] = useState(false);
  const [cities, setCities] = useState<City[]>();
  const [countries, setCountries] = useState<Country[]>();
  const [regions, setRegions] = useState<Region[]>();

  useEffect(() => {
    async function fetchCities() {
      const data = await availableSearchCity();
      setCities(data.cities);
      setCountries(data.countries);
      setRegions(data.regions);
    }
    fetchCities();
  }, []);
  const plans: any = [];

  useEffect(() => {
    const filterCityNameData = sessionStorage.getItem("filterCityName");
    const filterStartDateData = sessionStorage.getItem("filterStartDate");
    const filterEndDateData = sessionStorage.getItem("filterEndDate");
    if (filterCityNameData) {
      setCityName(JSON.parse(filterCityNameData));
      setCitySelected(true);
      if (filterStartDateData) {
        if (filterStartDateData != undefined) {
          setFilterStartDate(trimDate(JSON.parse(filterStartDateData)));
          setFilterEndDate(trimDate(JSON.parse(filterEndDateData!)));
        }
      }
    }
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe(
      "ResetFilter",
      resetFilterHandler
    );
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("DateFilter", dateFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("CityFilter", changeCityName);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  const resetFilterHandler = () => {
    setCityName("도시 선택");
    setFilterStartDate("");
    setFilterEndDate("");
    sessionStorage.setItem("filterCityName", JSON.stringify("도시 선택"));
    sessionStorage.setItem("filterStartDate", JSON.stringify(""));
    sessionStorage.setItem("filterEndDate", JSON.stringify(""));
    setCitySelected(false);
    setShowCitySeleciton(false);
    setShowCalendar(false);
  };

  const dateFilterHandler = (message: DateFilterMessage) => {
    setFilterStartDate(trimDate(message.startDate));
    setFilterEndDate(trimDate(message.endDate ?? ""));
    setShowCalendar(false);
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("popularPosts")
  };

  const onClickshowCitySelection = () => {
    setShowCitySeleciton((current) => !current);
  };

  const onClickShowCalendar = () => {
    setShowCalendar((current) => !current);
  };

  const changeCityName = (message: CityFilterMessage) => {
    sessionStorage.setItem("filterCityName", JSON.stringify(message.cityName));
    setCityName(message.cityName);
    setShowCitySeleciton(false);
    setCitySelected(true);
    sessionStorage.setItem("filterStartDate", JSON.stringify(""));
    sessionStorage.setItem("filterEndDate", JSON.stringify(""));
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("popularPosts")
  };

  function trimDateToStore(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  function trimDate(date: string) {
    let result = "";
    result = date.slice(5, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "360px",
        alignItems: "center",
        backgroundColor: "white",
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderRadius: "8px"
      }}
    >
      <MobileCityFilter
        cities={cities!}
        countries={countries!}
        regions={regions!}
      ></MobileCityFilter>
      <div className="mobile-filter-divider"></div>
      <MobileDateFilter></MobileDateFilter>
    </div>
    // <div
    //   className="mobile-filters"
    //   style={{ display: "flex", justifyContent: "flex-end" }}
    // >
    //   {props.type == "도시" && cityName == "도시 선택" && (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <Card
    //         variant="outlined"
    //         className="mobile-filter"
    //         sx={{
    //           cursor: "pointer",
    //           display: "flex",
    //           justifyContent: "left",
    //           alignItems: "center",
    //           borderRadius: "9px",
    //           zIndex: "3",
    //           width: "180px",
    //           marginLeft: "0px",
    //           height: "34px",
    //           borderTopRightRadius: "0px",
    //           borderBottomRightRadius: "0px",
    //         }}
    //         onClick={() => {
    //           onClickshowCitySelection();
    //         }}
    //       >
    //         <img className="icon-image" src={locationImage} />
    //         <div className="label">{cityName}</div>
    //       </Card>
    //       <div className="mobile-city-selection-card">
    //         {props.type == "도시" && showCitySeleciton && <CityFilter isModal={false} />}
    //       </div>
    //     </div>
    //   )}
    //   {props.type == "도시" && cityName != "도시 선택" && (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <div style={{ display: "flex" }}>
    //         <img
    //           src={refreshIcon}
    //           onClick={() => {
    //             eventBus.publish({
    //               topic: "ResetFilter",
    //               payload: { clicked: true },
    //             });
    //           }}
    //         />
    //         <Card
    //           variant="outlined"
    //           className="mobile-filter"
    //           sx={{
    //             cursor: "pointer",
    //             display: "flex",
    //             justifyContent: "left",
    //             alignItems: "center",
    //             borderRadius: "9px",
    //             zIndex: "3",
    //             color: "white",
    //             backgroundColor: "#777f82",
    //             width: "132px",
    //             height: "34px",
    //             borderTopRightRadius: "0px",
    //             borderBottomRightRadius: "0px",
    //             borderRightColor: "white",
    //             marginLeft: "14px",
    //           }}
    //           onClick={() => {
    //             onClickshowCitySelection();
    //           }}
    //         >
    //           <img className="icon-image" src={whiteLocationImage} />
    //           <div className="label" style={{ color: "white" }}>
    //             {cityName}
    //           </div>
    //         </Card>
    //       </div>
    //       <div className="mobile-city-selection-card">
    //         {props.type == "도시" && showCitySeleciton && <CityFilter isModal={false}/>}
    //       </div>
    //     </div>
    //   )}
    //   {props.type == "날짜" && !filterStartDate && (
    //     <div style={{ display: "flex", flexDirection: "row-reverse" }}>
    //       <Card
    //         variant="outlined"
    //         className="mobile-filter"
    //         sx={{
    //           cursor: "pointer",
    //           display: "flex",
    //           justifyContent: "left",
    //           alignItems: "center",
    //           zIndex: "1",
    //           borderRadius: "9px",
    //           width: "180px",
    //           height: "34px",
    //           borderTopLeftRadius: "0px",
    //           borderBottomLeftRadius: "0px",
    //           borderLeft: "none",
    //         }}
    //         onClick={() => {
    //           {
    //             {
    //               citySelected
    //                 ? onClickShowCalendar()
    //                 : alert("도시를 먼저 선택해주세요!");
    //             }
    //           }
    //         }}
    //       >
    //         <img className="icon-image" src={calendarImage} />
    //         <div className="label">날짜 선택</div>
    //       </Card>
    //       <div className="mobile-calendar" style={{ marginTop: "7px" }}>
    //         {showCalendar && <DayPickerRangeControllerWrapper />}
    //       </div>
    //     </div>
    //   )}
    //   {props.type == "날짜" && filterStartDate && (
    //     <div style={{ display: "flex", flexDirection: "row-reverse" }}>
    //       <Card
    //         variant="outlined"
    //         className="mobile-filter"
    //         sx={{
    //           cursor: "pointer",
    //           display: "flex",
    //           justifyContent: "left",
    //           alignItems: "center",
    //           borderRadius: "9px",
    //           zIndex: "3",
    //           color: "white",
    //           backgroundColor: "#777f82",
    //           width: "180px",
    //           marginLeft: "0px",
    //           height: "34px",
    //           borderTopLeftRadius: "0px",
    //           borderBottomLeftRadius: "0px",
    //           borderLeft: "none",
    //         }}
    //         onClick={() => {
    //           onClickShowCalendar();
    //         }}
    //       >
    //         <img className="icon-image" src={whiteCalendarImage} />
    //         {filterEndDate != "" && (
    //           <div className="label" style={{ color: "white" }}>
    //             {filterStartDate} - {filterEndDate}
    //           </div>
    //         )}
    //         {filterEndDate == "" && (
    //           <div className="label" style={{ color: "white" }}>
    //             {filterStartDate}
    //           </div>
    //         )}
    //       </Card>
    //       <div className="mobile-calendar" style={{ marginTop: "7px" }}>
    //         {showCalendar && <DayPickerRangeControllerWrapper />}
    //       </div>
    //     </div>
    //   )}

    //   {/* <div className="mobile-city-selection-card">
    //     {props.type == "도시" && showCitySeleciton && <CityFilter />}
    //   </div> */}
    // </div>
  );
};

export default MobileFilter;
