import { MobileWeb, Web } from "../../lib/react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import errorImage from "../../assets/img-404-error-326.svg";
import noticeImage from "../../assets/ic-404-notice-p-46.svg";
import "./not-found.scss";
import { useEffect, useState } from "react";

const NotFoundPage = (props: { errorTitle: string }) => {
  const navigate = useNavigate();
  const postId = useParams().postId;
  const userId = useParams().userId;

  const [hasId, setHasId] = useState<boolean>(false);

  useEffect(() => {
    if (postId || userId) {
      setHasId(true);
    }
  });

  const routeBack = () => {
    if (
      window.location.pathname === "/posts/not-found" ||
      window.location.pathname === "/users/not-found" ||
      window.location.pathname === "/users/deleted-user" ||
      window.location.pathname === "/posts/deleted"
    ) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  const routeMain = () => {
    navigate("/");
  };

  return (
    <div>
      <Web>
        {" "}
        <div
          style={{
            height: "calc(100vh - 300px)",
            backgroundColor: "#f7f7f8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img src={errorImage} />
          <div
            className="error-title"
            style={{ display: "flex", alignItems: "center", marginTop: "60px" }}
          >
            <img src={noticeImage} />
            <div className="error-message">{props.errorTitle}</div>
          </div>
          <div className="detail-error-message">
            방문하시려는 페이지의 주소가 잘못 입력되었거나,
            <br /> 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
            없습니다.
            <br /> 입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.{" "}
          </div>
          <div
            className="button-options"
            style={{ display: "flex", marginTop: "50px" }}
          >
            <div className="to-back-button" onClick={routeBack}>
              이전으로
            </div>
            <div className="to-main-button" onClick={routeMain}>
              메인으로
            </div>
          </div>
        </div>
      </Web>
      <MobileWeb>
        {" "}
        <div
          style={{
            height: "calc(100vh)",
            backgroundColor: "#f7f7f8",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            overflow: "hidden",
          }}
        >
          <div style={{ width: "360px" }}>
            {" "}
            <img src={errorImage} style={{ margin: "20px" }} />
            <div
              className="error-title"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "60px",
              }}
            >
              <div className="error-title">
                {" "}
                <img
                  src={noticeImage}
                  style={{ width: "22px", height: "22px" }}
                />
                <div className="error-message">{props.errorTitle}</div>
              </div>
            </div>
            <div className="detail-error-message">
              방문하시려는 페이지의 주소가 잘못 입력되었거나,
              <br /> 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를
              <br /> 찾을 수 없습니다.
              <br /> 입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.{" "}
            </div>
            <div
              className="button-options"
              style={{ display: "flex", marginTop: "50px" }}
            >
              <div className="to-back-button" onClick={routeBack}>
                이전으로
              </div>
              <div className="to-main-button" onClick={routeMain}>
                메인으로
              </div>
            </div>
          </div>
        </div>
      </MobileWeb>
    </div>
  );
};

export default NotFoundPage;
