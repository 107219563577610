import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Divider } from "@mui/material";
import requestCountImage from "../../../assets/ic-send.svg";
import viewCountImage from "../../../assets/View_Count_Icon.svg";
import shareIconImage from "../../../assets/Share_Icon.svg";
import calendarImage from "../../../assets/ic-filter-calendar-post.svg";
import linkImage from "../../../assets/ic-link-b-21.svg";
import emptyProfileImage from "../../../assets/empty-profile.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import User from "../../../data/models/user";

interface RecommendUserProps {
  post: any;
  user: any;
  deleted: boolean;
  participants: User[];
}

const WebDetailPostContainer: React.FC<RecommendUserProps> = ({
  post,
  user,
  deleted,
  participants,
}) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const routeDetailUser = (userId: string) => {
    navigate("/users/" + userId);
  };

  const routeDownload = () => {
    logEvent(analytics, "route_download", { name: "detail_post_request" });
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };

  function trimDate(date: string) {
    let result: string = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  const copyToClipboard = () => {
    var dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  return (
    <div>
      <div className="post" style={{ width: 597 }}>
        <div
          className="post-user"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: "500px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              routeDetailUser(user.userId);
            }}
          >
            <img
              src={
                !deleted && user.images && user.images.length > 0
                  ? user.images[0]
                  : emptyProfileImage
              }
              style={{
                height: "72px",
                width: "72px",
                borderRadius: "36px",
                marginRight: "16px",
              }}
            />
            <div>
              <div>{user.name}</div>
              <div
                style={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  fontFamily: "Spoqa Han Sans Neo",
                  fontWeight: 300,
                  fontSize: 15,
                  color: "#9da5a8",
                  marginTop: "4px"
                }}
              >
                {user.bio}
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {" "}
          <div
            className="post-location"
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <img
              src={post.city.country.flag}
              style={{
                marginRight: "8px",
                width: "16px",
                borderRadius: "8px",
                border: "1px solid #c6ced0",
              }}
            />
            {post.city.country.ko + ", " + post.city.ko}
          </div>
          <div
            className="post-location"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={calendarImage} style={{ marginRight: "10px" }} />
            {trimDate(post.startDate)} - {trimDate(post.endDate)}
          </div>
        </div>

        <div className="post-title">{post.title}</div>
        {participants.length > 0 ? (
          <div className="post-content" style={{ minHeight: "72px" }}>
            {post.contents}
          </div>
        ) : (
          <div className="post-content">{post.contents}</div>
        )}

        {participants.length > 0 ? (
          <div className="detail-post-participants-header">
            같이 여행해요
          </div>
        ) : (
          <></>
        )}

        <div className="mobile-detail-post-participants">
          {participants.length > 0 ? (
            <div
              style={{
                height: "52px",
                width: "100%",
                overflowY: "scroll",
                display: "flex",
                marginBottom: "16px",
              }}
            >
              {participants.map((participant) => (
                <img
                  src={participant.images[0]}
                  style={{
                    height: "52px",
                    borderRadius: "36px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    routeDetailUser(participant.userId);
                  }}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="detail-post-count-container">
          <div className="detail-post-counter">
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <img
                src={viewCountImage}
                style={{ height: "24px", marginRight: "8px" }}
              />{" "}
              {post.viewCount}{" "}
            </div>
          </div>

          <div
            className="detail-post-counter"
            style={{ cursor: "pointer" }}
            onClick={() => {
              copyToClipboard();
              alert("링크가 복사되었습니다.");
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <img
                src={shareIconImage}
                style={{ height: "24px", marginRight: "8px" }}
              />{" "}
              {"공유하기"}{" "}
            </div>
          </div>
        </div>

        <Divider sx={{ color: "#e8eced" }} />
        <Card
          sx={{
            width: "597px",
            height: "57px",
            border: "none",
            backgroundColor: "#35c5f5",
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "18px",
            fontWeight: "500",
            color: "#ffffff",
            borderRadius: "6px",
            marginTop: "38px",
            cursor: "pointer",
          }}
          onClick={() => {
            routeDownload();
          }}
        >
          동행 요청하기
        </Card>
      </div>
    </div>
  );
};

export default WebDetailPostContainer;
