import React from "react";
import { Card } from "@mui/material";
import "./filter-container.scss";
import Filter from "./filter/filter";
import { EventBusMessages } from "../../event-bus/event-bus-message";
import useEventBus from "../../event-bus";

const FilterContainer: React.FC = () => {
  const eventBus = useEventBus<EventBusMessages>();
  return (
    <div>
      <div className="sticky-top">
        <Card
          variant="outlined"
          className="filter-container"
          sx={{
            backgroundColor: "rgb(247,247,248, 0.5)",
            borderRadius: "7px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "none",
          }}
        >
          <div className="city-filter">
            <Filter type={"도시"}></Filter>
          </div>
          <div className="date-filter">
            <Filter type={"날짜"}></Filter>
          </div>
          <Card
            className="reset-button"
            sx={{ boxShadow: "none", color: "#9da5a8", cursor: "pointer" }}
            onClick={() => {
              eventBus.publish({
                topic: "ResetFilter",
                payload: { clicked: true },
              });
            }}
          >
            초기화
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FilterContainer;
