import React from "react";
import { useNavigate } from "react-router-dom";
import "./nav-bar.scss";
import logo from "../../assets/ic-home-logo-wanderlust.svg";
import { MobileWeb, Web } from "../../lib/react-responsive";

const NavBar: React.FC<{}> = () => {
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate("/");
    window.sessionStorage.clear();
  };

  return (
    <div className="nav">
      <Web>
        <div className="header">
          <div className="asd">
            <img src={logo} onClick={handleClickLogo}></img>
          </div>
          {/* <div className="menu">동행해요</div> */}
        </div>
      </Web>
      <MobileWeb>
        <div className="mobile-header">
          <img
            src={logo}
            style={{ width: "120px", height: "20px" }}
            onClick={handleClickLogo}
          ></img>
        </div>
      </MobileWeb>
    </div>
  );
};

export default NavBar;
