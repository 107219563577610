import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent, Typography, Divider } from "@mui/material";
import "./itinerary-card.scss";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import PlanCard from "./plan-card/plan-card";
import { getItinerariesData } from "../../usecases/get-itinerary-use-case";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import { getPlans } from "../../data/services/plans-api-service";
import EmptyItinerary from "./empty-itinerary";
import { MobileWeb, Web } from "../../lib/react-responsive";

interface ItineraryCardProps {
  itinerary: any;
}

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
  height: 183;
  width: 575;
  border: none;
  boxShadow: none;
`);

const useStyles = makeStyles((theme) => ({
  card: {
    borderColor: "#e8eced",
    borderWidth: "1px",
    height: "180x",
    borderRadius: "12px",
  },
  title: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 400,
    fontSize: 20,
    height: "49px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contents: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 300,
    lineHeight: 1.6,
    maxHeight: 50,
    paddingBottom: 12,
    fontSize: 17,
  },
  time: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 400,
    fontSize: 18,
    color: "#57676d",
    display: "flex",
    alignItems: "baseline",
    paddingLeft: "17px",
  },
  userName: {
    fontFamily: "Spoqa Han Sans Neo",
    fontWeight: 300,
    fontSize: 15,
  },
  avatar: {
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {},
  },
}));

const ItineraryCard: React.FC<{}> = () => {
  const userId = useParams().userId;
  const navigate = useNavigate();
  const classes = useStyles();

  const [itineraries, setItineraries] = useState<any>([]);
  const [itinerariesCount, setItinerariesCount] = useState<number>(0);
  const [needToLoadMoreItineraries, setNeedToLoadMoreItineraries] =
    useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getItinerariesData(userId!);
      setItinerariesCount(data[0]);
      setItineraries(data[1]);

      if (data[0] > 10) {
        setNeedToLoadMoreItineraries(true);
      } else {
        setNeedToLoadMoreItineraries(false);
      }
    }
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const fetchMoreItineraries = () => {
    var data: any;
    var params: any = {};
    params.type = "GATHERING";
    setTimeout(async () => {
      data = await getItinerariesData(
        userId!,
        itineraries![itineraries.length - 1]._id
      );
      let appendedItineraries = itineraries?.concat(data[1]);
      setItineraries(appendedItineraries);
      if (data[1].length == 10) {
        setNeedToLoadMoreItineraries(true);
      } else {
        setNeedToLoadMoreItineraries(false);
      }
    }, 1500);
  };

  const routeDetailPost = (itineraryId: string) => {
    navigate("/users/" + userId! + "/itineraries/" + itineraryId);
  };

  function getDifferenceInDays(date1: string, date2: string) {
    const start = new Date(date1);
    const end = new Date(date2);
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(end) - Number(start)) / msInDay) + 1;
  }

  function trimDate(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  return (
    <div>
      <div className="itineraries-section-header">
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "18px",
            fontWeight: "500",
            // color: "#3b3f41",
            color: "black",
            opacity: "0.8",
            marginRight: "6px",
          }}
        >
          여행 일정
        </div>{" "}
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "18px",
            fontWeight: "500",
            color: "#35c5f5",
          }}
        >
          {itinerariesCount}
        </div>
      </div>
      <Web>
        <InfiniteScroll
          dataLength={itineraries?.length ?? 0}
          next={fetchMoreItineraries}
          hasMore={needToLoadMoreItineraries}
          loader={
            <CircularProgress
              sx={{
                color: "#35C5f5",
                marginLeft: "267.5px",
                marginTop: "30px",
              }}
            />
          }
          style={{ overflow: "hidden" }}
        >
          {itineraries ? (
            <div>
              {itineraries.length > 0 ? (
                itineraries.map((itinerary: any) => (
                  <Card
                    className={classes.card}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      border: "solid 1px #e8eced",
                      marginBottom: "16px",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      routeDetailPost(itinerary.itineraryId);
                    }}
                  >
                    <Typography
                      className={classes.title}
                      gutterBottom
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        backgroundColor: itinerary.primaryColor,
                        color: itinerary.accentColor,
                      }}
                    >
                      {itinerary.title}
                    </Typography>

                    <Typography className={classes.time}>
                      <div
                        style={{
                          fontSize: "30px",
                          fontWeight: "500",
                          color: "#2a2d2e",
                          paddingRight: "5px",
                        }}
                      >
                        {getDifferenceInDays(
                          itinerary.startDate,
                          itinerary.endDate
                        )}
                      </div>{" "}
                      <div style={{ paddingRight: "16px" }}>일</div>
                      <div>
                        {trimDate(itinerary.startDate)} -{" "}
                        {trimDate(itinerary.endDate)}
                      </div>
                    </Typography>
                    <PlanCard
                      itineraryId={itinerary.itineraryId}
                      userId={userId!}
                    ></PlanCard>
                  </Card>
                ))
              ) : (
                <EmptyItinerary text="등록된 여행 일정이 없습니다."></EmptyItinerary>
              )}
            </div>
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </Web>
      <MobileWeb>
        <InfiniteScroll
          dataLength={itineraries?.length ?? 0}
          next={fetchMoreItineraries}
          hasMore={needToLoadMoreItineraries}
          loader={
            <CircularProgress
              sx={{
                color: "#35C5f5",
                marginLeft: "160px",
                marginTop: "30px",
              }}
            />
          }
          style={{ overflow: "hidden" }}
        >
          {itineraries ? (
            <div>
              {itineraries.length > 0 ? (
                itineraries.map((itinerary: any) => (
                  <Card
                    className={classes.card}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      border: "solid 1px #e8eced",
                      boxShadow: "none",
                      marginBottom: "12px",
                    }}
                    onClick={() => {
                      routeDetailPost(itinerary.itineraryId);
                    }}
                  >
                    <Typography
                      className={classes.title}
                      gutterBottom
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        backgroundColor: itinerary.primaryColor,
                        color: itinerary.accentColor,
                      }}
                    >
                      {itinerary.title}
                    </Typography>

                    <Typography className={classes.time}>
                      <div
                        style={{
                          fontSize: "24px",
                          fontWeight: "500",
                          color: "#2a2d2e",
                          paddingRight: "5px",
                        }}
                      >
                        {getDifferenceInDays(
                          itinerary.startDate,
                          itinerary.endDate
                        )}
                      </div>{" "}
                      <div style={{ paddingRight: "16px" }}>일</div>
                      <div>
                        {trimDate(itinerary.startDate)} -{" "}
                        {trimDate(itinerary.endDate)}
                      </div>
                    </Typography>
                    <PlanCard
                      itineraryId={itinerary.itineraryId}
                      userId={userId!}
                    ></PlanCard>
                  </Card>
                ))
              ) : (
                <EmptyItinerary text="등록된 여행 일정이 없습니다."></EmptyItinerary>
              )}
            </div>
          ) : (
            <></>
          )}
        </InfiniteScroll>
      </MobileWeb>
    </div>
  );
};

export default ItineraryCard;
