import { useMediaQuery } from "react-responsive";

export const Web = ({ children }: any) => {
  const isWeb = useMediaQuery({ minWidth: 1100 });
  return isWeb ? children : null;
};
export const MobileWeb = ({ children }: any) => {
  const isMobileWeb = useMediaQuery({ maxWidth: 1099 });
  return isMobileWeb ? children : null;
};
