import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import blueDot from "../../assets/ic-web-circle-p-5.svg";
import { MobileWeb, Web } from "../../lib/react-responsive";

interface RecommendProps {
  latestPosts: [any];
  relatedPosts?: [any];
}

const RecommendContainer: React.FC<RecommendProps> = ({
  latestPosts,
  relatedPosts,
}) => {
  const navigate = useNavigate();

  const routeDetailPost = (postId: string) => {
    navigate("/posts/" + postId);
  };

  const routePostList = () => {
    navigate("/");
  };

  return (
    <div>
      <Web>
        <Card
          className="post-recommandations"
          variant="outlined"
          sx={{
            width: "317px",
            minHeight: "332px",
            // height: "635px",
            boxShadow: "none",
            borderColor: "#e8eced",
            borderWidth: "1px",
          }}
        >
          <Typography
            className={"recommandation-header"}
            color="#57676d"
            gutterBottom
            sx={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "18px",
              marginLeft: "19px",
              marginBottom: "0px",
            }}
          >
            최신 모집글
          </Typography>
          <Typography
            className={"recommandation-contents"}
            color="#777f82"
            sx={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "13px",
              marginLeft: "19px",
              marginBottom: "",
            }}
          >
            {latestPosts ? (
              Array.from(latestPosts).map((post: any) => (
                <div
                  style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    routeDetailPost(post.postId);
                  }}
                >
                  <img
                    src={blueDot}
                    style={{ marginRight: "10px", height: "5px", width: "5px" }}
                  />
                  <div
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {post.title}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </Typography>
          {relatedPosts ? (
            <div style={{ minHeight: "320px" }}>
              {" "}
              <Typography
                className={"recommandation-header"}
                color="#57676d"
                gutterBottom
                sx={{
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: "18px",
                  marginLeft: "19px",
                  marginBottom: "19px",
                }}
              >
                연관 모집글
              </Typography>
              <Typography
                className={"recommandation-contents"}
                color="#777f82"
                sx={{
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "13px",
                  marginLeft: "19px",
                  marginBottom: "",
                }}
              >
                {relatedPosts ? (
                  Array.from(relatedPosts).map((post: any) => (
                    <div
                      style={{
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        routeDetailPost(post.postId);
                      }}
                    >
                      <img src={blueDot} style={{ marginRight: "10px" }} />
                      <div
                        style={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {post.title}
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Typography>
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              height: "49px",
              width: "100%",
              backgroundColor: "#f4f5f5",
              position: "absolute",
              bottom: "0",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "15px",
              fontWeight: "400",
              color: "#777f82",
              cursor: "pointer",
              marginTop: "19px",
            }}
            onClick={() => {
              routePostList();
            }}
          >
            모집글 더보기 {">"}
          </div>
        </Card>
      </Web>
      <MobileWeb>
        <Card
          className="post-recommandations"
          variant="outlined"
          sx={{
            width: "360px",
            minHeight: "332px",
            // height: "635px",
            boxShadow: "none",
            borderColor: "#e8eced",
            borderWidth: "1px",
            marginBottom: "15px",
          }}
        >
          <Typography
            className={"recommandation-header"}
            color="black"
            gutterBottom
            sx={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "18px",
              fontWeight: "500",
              marginTop: "18px",
              marginLeft: "19px",
              marginBottom: "0px",
            }}
          >
            최신 모집글
          </Typography>
          <Typography
            className={"recommandation-contents"}
            color="#777f82"
            sx={{
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "8px",
              marginLeft: "19px",
              marginBottom: "",
            }}
          >
            {latestPosts ? (
              Array.from(latestPosts).map((post: any) => (
                <div
                  style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    routeDetailPost(post.postId);
                  }}
                >
                  <img
                    src={blueDot}
                    style={{ marginRight: "10px", height: "5px", width: "5px" }}
                  />
                  <div
                    style={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 1,
                    }}
                  >
                    {post.title}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </Typography>
          {relatedPosts ? (
            <div style={{ minHeight: "320px" }}>
              {" "}
              <Typography
                className={"recommandation-header"}
                color="black"
                gutterBottom
                sx={{
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: "18px",
                  marginLeft: "19px",
                }}
              >
                연관 모집글
              </Typography>
              <Typography
                className={"recommandation-contents"}
                color="#777f82"
                sx={{
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginTop: "8px",
                  marginLeft: "19px",
                  marginBottom: "",
                }}
              >
                {relatedPosts ? (
                  Array.from(relatedPosts).map((post: any) => (
                    <div
                      style={{
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        routeDetailPost(post.postId);
                      }}
                    >
                      <img src={blueDot} style={{ marginRight: "10px" }} />
                      <div
                        style={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {post.title}
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </Typography>
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              height: "49px",
              width: "100%",
              backgroundColor: "#f4f5f5",
              position: "absolute",
              bottom: "0",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              fontFamily: "Spoqa Han Sans Neo",
              fontSize: "15px",
              fontWeight: "400",
              color: "#777f82",
              cursor: "pointer",
              marginTop: "19px",
            }}
            onClick={() => {
              routePostList();
            }}
          >
            모집글 더보기 {">"}
          </div>
        </Card>
      </MobileWeb>
    </div>
  );
};

export default RecommendContainer;
