import React from "react";
import { Card } from "@mui/material";
import Filter from "../filter-container/filter/filter";
import { EventBusMessages } from "../../event-bus/event-bus-message";
import useEventBus from "../../event-bus";
import image from '../../assets/img-signin.svg'
import xImage from "../../assets/ic-navi-xmark-g-44.svg"
import mobileImage from "../../assets/img-signin-mobile.svg";
import mobileXImage from "../../assets/ic-navi-xmark-g-24.svg"
import { MobileWeb, Web } from "../../lib/react-responsive";
import { getAnalytics, logEvent } from "firebase/analytics";

const CityFilterModal: React.FC = () => {
  const eventBus = useEventBus<EventBusMessages>();
  const analytics = getAnalytics();
  return (
      <div>
      <Web>
        <div
          style={{
            backgroundColor: "#f7f7f8",
            borderRadius: "7px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            border: "none",
            width: "970px",
            height: "588px",
            zIndex: "10",
          }}
        >
            <img src={xImage} style={{position: "absolute" ,right:"0px", margin: "20px"}} onClick={() => {
              logEvent(analytics, 'close_filter_modal');
              eventBus.publish({
                topic: "CloseModal",
                payload: { clicked: true },
              });}} />
          <div className="city-filter">
            <Filter type={"어디로 여행하시나요?"}></Filter>
          </div>
          <img src={image} style={{position: "absolute", bottom: "0", margin: "100px"}}/>
        </div>
      </Web>
      <MobileWeb>
      <div
          style={{
            backgroundColor: "#f7f7f8",  
            borderRadius: "7px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            border: "none",
            width: "360px",
            height: "420px",
            zIndex: "10",
          }}
        >
            <img src={mobileXImage} style={{position: "absolute" ,right:"0px", margin: "12px"}} onClick={() => {
              logEvent(analytics, 'close_filter_modal');
              eventBus.publish({
                topic: "CloseModal",
                payload: { clicked: true },
              });}} />
          <div className="city-filter">
            <Filter type={"mobile-modal"}></Filter>
          </div>
          <img src={mobileImage} style={{position: "absolute", bottom: "0", margin: "50px", height: "160px", width: "195px"}}/>
        </div>
      </MobileWeb>
      </div>


  );
};

export default CityFilterModal;
