import React, { useEffect, useState } from "react";
import CityPinImage from "../../../assets/ic-plan-city-gray-52.svg";
import barImage from "../../../assets/ic-plan-city-bar-gray-22.svg";
import backBarImage from "../../../assets/ic-plan-bar-back-gray-27.svg";
import "./plan-card.scss";
import { getItineraryData } from "../../../usecases/get-itinerary-use-case";

const PlanCard = (props: { itineraryId: string; userId: string }) => {
  const [plans, setPlans] = useState<any>([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getItineraryData(props.userId!, props.itineraryId!);
      setPlans(data.plans);
    }
    fetchData();
  }, [props]);

  function trimDate(date: string) {
    let result = "";
    result = date.slice(5, 10);
    result = result.replaceAll("-", ".");
    return result;
  }
  return (
    <div>
      {plans.length > 0 ? (
        <div className="plan-cards">
          {plans.map((plan: any) => (
            <div className="plan-container">
              <div className="plan-image">
                <div className="pin">
                  {" "}
                  <object
                    type="image/svg+xml"
                    data={CityPinImage}
                    style={{ zIndex: "2" }}
                  >
                    <img className="city-pin-image" src={CityPinImage} />
                  </object>
                  <img
                    className="flag-image"
                    src={plan.city.country.flag}
                  ></img>
                </div>

                <img className="bar-image" src={barImage} />
                {plan === plans[plans.length - 1] ? (
                  <></>
                ) : (
                  <img className="back-bar-image" src={backBarImage} />
                )}

                {plan.memos.length > 0 ? (
                  <div className="memo-count-container">
                    <div className="memo-white-dot" />
                    <div className="memo-count">{plan.memos.length}</div>{" "}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="plan-info">
                <div
                  className="plan-city-name"
                  style={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                >
                  {plan.city.ko}
                </div>
                <div className="plan-start-date">
                  {trimDate(plan.startDate)}
                </div>
              </div>
            </div>
          ))}{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlanCard;
