import {getUser} from "../data/services/users-api-service";
import {getLatestPosts, getRecommendedUsers} from "../data/services/recommend-api-service";
import {getItineraries} from "../data/services/itinerary-api-service";
import {getPostsOfUser} from "../data/services/posts-api-service";

export const getDetailProfileData = async (userId: string) => {
    return await getUser(userId);
};

export const getItinerariesData = async (userId: string, itineraryObjectId?: string) => {
    return await getItineraries(userId, itineraryObjectId);
};

export const getPostsOfUserData = async (userId: string, updatedAt?: string) => {
    return await getPostsOfUser(userId, updatedAt);
};

export const getRecommendedData = async () => {
    const latestPosts = await getLatestPosts();
    const recommendedUsers = await getRecommendedUsers();
    return [latestPosts, recommendedUsers];
};
