import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getItineraryData } from "../../usecases/get-itinerary-use-case";
import { format } from "date-fns";
import { DateRange, RangeKeyDict } from "react-date-range";

import { Divider } from "@mui/material";
import "./detail-itinerary.scss";
import calendarImage from "../../assets/ic-plan-date-gray-18.svg";
import { isMobile } from "react-device-detect";
import { getAnalytics, logEvent } from "firebase/analytics";

interface Plan {
  startDate: Date;
  endDate: Date;
  color: string;
  key: string;
}

interface PlanStartDay {
  date: string;
  city: string;
}

const DetailItinerary: React.FC = () => {
  const userId = useParams().userId;
  const itineraryId = useParams().itineraryId;

  const [itinerary, setItinerary] = useState<any>([]);
  const [plans, setPlans] = useState<Plan[]>();
  const [memoDates, setMemoDates] = useState<any>([]);
  const [planStartDates, setPlanStartDates] = useState<PlanStartDay[]>([]);
  const [hoveredDate, setHoveredDate] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [clickedDate, setClickedDate] = useState<any>();

  const [clickedValue, setClickedValue] = useState<any>();
  const [clickedCity, setClickedCity] = useState<any>("");
  const [clickedCityImage, setClickedCityImage] = useState<string>("");
  const [clickedCityStartDate, setClickedCityStartDate] = useState<string>("");
  const [clickedCityEndDate, setClickedCityEndDate] = useState<string>("");
  const [clickedCityMemo, setClickedCityMemo] = useState<string>("");
  const weekday = ["일", "월", "화", "수", "목", "금", "토"];
  const analytics = getAnalytics();

  useEffect(() => {
    async function fetchData() {
      const data = await getItineraryData(userId!, itineraryId!);
      let plans: Plan[] = [];
      data.plans.forEach((item: any) => {
        let plan: Plan = {
          startDate: new Date(),
          endDate: new Date(),
          color: "",
          key: "",
        };
        plan.startDate = new Date(item.startDate);
        plan.endDate = new Date(item.endDate);
        plan.color = "#edecee66";
        plan.key = item.planId;
        plans.push(plan);
      });
      setItinerary(data);
      setPlans(plans);
      setHoveredDate(plans[0].startDate);
      setSelectedDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "default-selection",
        },
      ]);
    }

    if (userId) {
      fetchData();
      logEvent(analytics, 'web_screen_view', {
        firebase_screen: "web_detail_itinarary_page",
        firebase_screen_class: 'WebDetailItineraryPage'
      });
    }
  }, [userId, itineraryId]);

  useEffect(() => {
    if (!isMobile) {
      setClickedCityMemo("");
      setClickedDate(hoveredDate);
      findPlan(hoveredDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isMobile) {
      setClickedCityMemo("");
      setClickedDate(hoveredDate);
      findPlan(hoveredDate);
    }
  }, [hoveredDate]);

  useEffect(() => {
    if (itinerary) {
      let memoDateContainer: any = [];
      let planStartDateContainer: PlanStartDay[] = [];
      itinerary.plans?.forEach((plan: any) => {
        let planStartDate: PlanStartDay = { date: "", city: "" };
        planStartDate.date = plan.startDate;
        planStartDate.city = plan.city.ko;
        planStartDateContainer.push(planStartDate);
        plan.memos?.forEach((memo: any) => {
          memoDateContainer.push(memo.date);
        });
      });
      setPlanStartDates(planStartDateContainer);
      setMemoDates(memoDateContainer);
    }
  }, [itinerary]);

  function customDayContent(day: any) {
    const extraDot = <div className="extra-dot" />;

    return (
      <div>
        {" "}
        {clickedDate &&
        format(clickedDate, "yyyy-MM-dd") === format(day, "yyyy-MM-dd") ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="custom-day-number-selected">{format(day, "d")}</div>
            {memoDates.map((memoDate: any) =>
              memoDate === format(day, "yyyy-MM-dd") ? extraDot : <></>
            )}
            {planStartDates.map((startDate: PlanStartDay) =>
              startDate.date === format(day, "yyyy-MM-dd") ? (
                <div className="city-label"> {startDate.city}</div>
              ) : (
                <></>
              )
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="custom-day-number">{format(day, "d")}</div>
            {memoDates.map((memoDate: any) =>
              memoDate === format(day, "yyyy-MM-dd") ? extraDot : <></>
            )}
            {planStartDates.map((startDate: PlanStartDay) =>
              startDate.date === format(day, "yyyy-MM-dd") ? (
                <div className="city-label"> {startDate.city}</div>
              ) : (
                <></>
              )
            )}
          </div>
        )}
      </div>
    );
  }

  function getDifferenceInDays(date1: string, date2: string) {
    const start = new Date(date1);
    const end = new Date(date2);
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(end) - Number(start)) / msInDay);
  }

  function getDifferenceInDaysPlus(date1: string, date2: string) {
    const start = new Date(date1);
    const end = new Date(date2);
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(end) - Number(start)) / msInDay) + 1;
  }

  function trimDate(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  function findPlan(date: Date) {
    if (date) {
      itinerary.plans?.forEach((plan: any) => {
        let startDate = new Date(plan.startDate);
        let endDate = new Date(plan.endDate);
        let dateToCheck = format(date, "yyyy-MM-dd");
        let formattedDate = new Date(dateToCheck);
        if (formattedDate >= startDate && formattedDate <= endDate) {
          setClickedCity(plan.city.ko);
          setClickedCityImage(plan.city.country.flag);
          setClickedCityStartDate(plan.startDate);
          setClickedCityEndDate(plan.endDate);
          findMemo(dateToCheck.toString(), plan);
        }
      });
    }
  }

  function findMemo(date: string, plan: any) {
    plan.memos.forEach((memo: any) => {
      if (memo.date === date) {
        setClickedCityMemo(memo.contents);
      }
    });
  }

  return (
    <main
      style={{
        minHeight: "calc(~100vh - 300px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      {itinerary.startDate ? (
        <div style= {{marginTop: "18px"}}>
          {" "}
          <div className="calendar-header">{itinerary.title}</div>
          <div className="calendar-subheader">
            {" "}
            <p
              style={{
                margin: "16px",
                fontFamily: "Roboto",
                fontWeight: "900",
                fontSize: "24px",
              }}
            >
              {getDifferenceInDays(itinerary.startDate, itinerary.endDate)}
            </p>
            <p
              style={{
                margin: "0px",
                fontFamily: "Spoqa Han Sans Neo",
                fontWeight: "400",
                fontSize: "16px",
                marginRight: "16px",
              }}
            >
              일
            </p>
            <p
              style={{
                margin: "0px",
                fontFamily: "Spoqa Han Sans Neo",
                fontWeight: "400",
                fontSize: "16px",
              }}
            >
              {trimDate(itinerary.startDate)} - {trimDate(itinerary.endDate)}
            </p>
          </div>
          <Divider className="divider" />
          <div>
            {itinerary && plans && plans.length > 0 ? (
              <DateRange
                color={"09090a"}
                dayContentRenderer={customDayContent}
                dragSelectionEnabled={false}
                maxDate={plans![plans!.length - 1].endDate}
                minDate={plans![0].startDate}
                ranges={plans}
                showDateDisplay={false}
                showMonthAndYearPickers={false}
                showPreview={false}
                onPreviewChange={(date) => setHoveredDate(date)}
                onChange={(date) => setSelectedDate(date)}
                date={hoveredDate}
              />
            ) : (
              <></>
            )}
            {clickedDate ? (
              <div>
                {" "}
                <Divider className="divider" />
                <div className="date-day-label">
                  {format(clickedDate, "do").slice(0, -2)}일{" "}
                  {weekday[clickedDate.getDay()]}요일
                </div>
                <Divider className="divider" />
                <div className="city-day-label">
                  <div className="labels">
                    <img
                      src={clickedCityImage}
                      style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "9px",
                        marginRight: "13px",
                      }}
                    ></img>
                    <div>{clickedCity}</div>
                  </div>
                  <div className="labels">
                    <img
                      src={calendarImage}
                      style={{ marginRight: "13px" }}
                    ></img>{" "}
                    <div>
                      {getDifferenceInDays(
                        clickedCityStartDate,
                        clickedCityEndDate
                      ) === 0
                        ? trimDate(clickedCityStartDate) +
                          " - " +
                          trimDate(clickedCityEndDate) +
                          " (" +
                          "당일" +
                          ")"
                        : trimDate(clickedCityStartDate) +
                          " - " +
                          trimDate(clickedCityEndDate) +
                          " (" +
                          getDifferenceInDays(
                            clickedCityStartDate,
                            clickedCityEndDate
                          ) +
                          "박" +
                          getDifferenceInDaysPlus(
                            clickedCityStartDate,
                            clickedCityEndDate
                          ) +
                          "일)"}
                    </div>
                  </div>
                </div>
                <Divider className="divider" />
                {clickedCityMemo ? (
                  <div className="memo-container">
                    <div className="memo-title">세부일정</div>
                    <div className="memo-contents">{clickedCityMemo}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>{" "}
        </div>
      ) : (
        <></>
      )}
    </main>
  );
};

export default DetailItinerary;
