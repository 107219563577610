import {getPopularPosts, GetPostsQueryParameters} from "../data/services/posts-api-service";
import {getUser} from "../data/services/users-api-service";

export const getPopularPostsDataIncludeUsers = async ({params}: GetPostsQueryParameters) => {
    let users: any[] = [];
    const posts = await getPopularPosts({params});
    const userIds = posts.map((post: any) => post.authorId);
    const uniqueUserIds = new Set<string>(userIds);
    for (const userId of uniqueUserIds) {
        const user = await getUser(userId);
        users.push(user);
    }
    return [posts, users];
};
