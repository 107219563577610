import React, { useEffect, useInsertionEffect, useState } from "react";
import "./mobile-filter-cell.scss";
import City from "../../../data/models/city";
import Country from "../../../data/models/country";
import Region from "../../../data/models/region";
import GlobeImage from "../../../assets/icFilterGlobe38.svg";
import {
  EventBusMessages,
  CityFilterMessage,
} from "../../../event-bus/event-bus-message";
import useEventBus from "../../../event-bus";

const MobileCityFilter = (props: {
  cities: City[];
  countries: Country[];
  regions: Region[];
}) => {
  const eventBus = useEventBus<EventBusMessages>();
  const [selectedCity, setSelectedCity] = useState<string>("");

  useEffect(() => {
    const storedCity = sessionStorage.getItem("filterCityName");
    const cityName = storedCity?.replaceAll('"', "");
    console.log("storedCity is " + storedCity);
    if (storedCity !== undefined && storedCity !== null) {
      setSelectedCity(cityName!);
    }
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("CityFilter", cityFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  function onClickCity(cityName: string, cityId: string, cityType: any) {
    if (cityId === "") {
      setSelectedCity("");
    }
    sessionStorage.setItem("filterCityName", JSON.stringify(cityName));
    setSelectedCity(cityName);
    eventBus.publish({
      topic: "CityFilter",
      payload: { city: cityId, cityType: cityType, cityName: cityName },
    });
  }

  const cityFilterHandler = (message: CityFilterMessage) => {
    setSelectedCity(message.cityName);
  };

  return (
    <div
      style={{ width: "350px", overflowY: "scroll", scrollbarColor: "white" }}
    >
      <div className="city-container">
        {selectedCity !== "" ? (
          <div
            className="mobile-filter-cell"
            onClick={() => onClickCity("", "", "")}
          >
            <div className="city-filter-image-container">
              <img
                className="flag"
                src={GlobeImage}
                style={{
                  width: "38px",
                  height: "38px",
                  borderRadius: "19px",
                }}
              />
            </div>
            <div className="city-filter-label">{"전체"}</div>
          </div>
        ) : (
          <div
            className="mobile-filter-cell"
            onClick={() => onClickCity("", "", "")}
          >
            <div className="selected-city-filter-image-container">
              <img
                className="flag"
                src={GlobeImage}
                style={{
                  width: "38px",
                  height: "38px",
                  borderRadius: "19px",
                }}
              />
            </div>
            <div className="selected-city-filter-label">{"전체"}</div>
          </div>
        )}

        {props.cities ?? [].length > 0 ? (
          props.cities!.map((city) => (
            <div className="mobile-filter-cell"></div>
          ))
        ) : (
          <p></p>
        )}
        {props.countries ?? [].length > 0 ? (
          props.countries!.map((country) => (
            <div>
              {selectedCity !== country.ko ? (
                <div
                  className="mobile-filter-cell"
                  onClick={() =>
                    onClickCity(country.ko, country.countryId, {
                      countryId: country.countryId,
                    })
                  }
                >
                  <div className="city-filter-image-container">
                    <img
                      className="flag"
                      src={country.flag}
                      style={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "19px",
                      }}
                    />
                  </div>
                  <div className="city-filter-label">{country.ko}</div>
                </div>
              ) : (
                <div
                  className="mobile-filter-cell"
                  onClick={() =>
                    onClickCity(country.ko, country.countryId, {
                      countryId: country.countryId,
                    })
                  }
                >
                  <div className="selected-city-filter-image-container">
                    <img
                      className="flag"
                      src={country.flag}
                      style={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "19px",
                      }}
                    />
                  </div>
                  <div className="selected-city-filter-label">{country.ko}</div>
                </div>
              )}{" "}
            </div>
          ))
        ) : (
          <p></p>
        )}
        {props.regions ?? [].length > 0 ? (
          props.regions!.map((region) => (
            <div>
              {" "}
              {selectedCity !== region.ko ? (
                <div
                  className="mobile-filter-cell"
                  onClick={() =>
                    onClickCity(region.ko, region.regionId, {
                      regionId: region.regionId,
                    })
                  }
                >
                  <div className="city-filter-image-container">
                    <img
                      className="flag"
                      src={region.flag}
                      style={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "19px",
                      }}
                    />
                  </div>
                  <div className="city-filter-label">{region.ko}</div>
                </div>
              ) : (
                <div
                  className="mobile-filter-cell"
                  onClick={() =>
                    onClickCity(region.ko, region.regionId, {
                      regionId: region.regionId,
                    })
                  }
                >
                  <div className="selected-city-filter-image-container">
                    <img
                      className="flag"
                      src={region.flag}
                      style={{
                        width: "38px",
                        height: "38px",
                        borderRadius: "19px",
                      }}
                    />
                  </div>
                  <div className="selected-city-filter-label">{region.ko}</div>
                </div>
              )}{" "}
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};
export default MobileCityFilter;
