import { getDetailPost } from "../data/services/detail-post-api-service";
import { getUser } from "../data/services/users-api-service";
import {
  getLatestPosts,
  getRecommendedUsers,
  getRelatedPosts,
} from "../data/services/recommend-api-service";

export const getDetailPostData = async (postId: string) => {
  var user: any;
  const post = await getDetailPost(postId);
  if (post) {
    user = await getUser(post.authorId);
  } else {
    user = undefined;
  }
  return [post, user];
};

export const getRecommendedData = async (
  countryId: string,
  startDate: string,
  endDate: string
) => {
  const latestPosts = await getLatestPosts();
  const relatedPosts = await getRelatedPosts(countryId, startDate, endDate);
  const recommendedUsers = await getRecommendedUsers();
  return [latestPosts, relatedPosts, recommendedUsers];
};
