import React from "react";
import ItineraryCard from "../../../components/itinerary-card/itinerary-card";
import Posts from "../../../components/posts/posts";
import noInstagramIcon from "../../../assets/ic-instagram-off-line-g-22.svg";
import hasInstagramIcon from "../../../assets/ic-instagram-on-line-g-22.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, CircularProgress } from "@mui/material";
import emptyProfileImage from "../../../assets/empty-profile.svg";
import linkImage from "../../../assets/ic-link-b-21.svg";
import EmptyItinerary from "../../itinerary-card/empty-itinerary";

interface RecommendUserProps {
  posts: any[];
  user: any;
  needToLoadMorePosts: Boolean;
  users: any[];
  fetchMorePosts: () => any;
  currentTabNumber: any;
  onClickItineraryTab: () => any;
  onClickPostTab: () => any;
}

const WebDetailProfileContainer: React.FC<RecommendUserProps> = ({
  posts,
  user,
  needToLoadMorePosts,
  users,
  fetchMorePosts,
  currentTabNumber,
  onClickItineraryTab,
  onClickPostTab,
}) => {
  const onClickInstagramHandler = (instagramId: string) => {
    window.location.replace("https://instagram.com/" + instagramId);
  };

  return (
    <div>
      <div className="post" style={{ width: 597 }}>
        <div className="detail-profile">
          <img
            className="profile-image"
            src={
              user.images && user.images.length > 0
                ? user.images[0]
                : emptyProfileImage
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "450px",
            }}
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="user-name" style={{ marginBottom: "10px" }}>
                {" "}
                {user.name}{" "}
              </div>
              {user.instagramId ? (
                <div>
                  {" "}
                  {user.isPrivateInstagramId ? (
                    <div className="instagram-section">
                      <img src={hasInstagramIcon}></img>
                      <div className="instagram-id">아이디 비공개</div>
                    </div>
                  ) : (
                    <div
                      className="instagram-section"
                      onClick={() => onClickInstagramHandler(user.instagramId)}
                    >
                      <img src={hasInstagramIcon}></img>
                      <div className="instagram-id">{user.instagramId}</div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="instagram-section">
                  <img
                    src={noInstagramIcon}
                    style={{ marginRight: "10px" }}
                  ></img>
                  <div className="instagram-id"></div>
                </div>
              )}
            </div>
            <Card
              variant="outlined"
              sx={{
                padding: "10px",
                borderRadius: "3px",
                border: "solid 1px #e8eced",
                backgroundColor: "rgba(244, 245, 245, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "43px",
                cursor: "pointer",
              }}
              onClick={() => {
                // copyToClipboard();
                alert("링크가 복사되었습니다.");
              }}
            >
              {" "}
              <img src={linkImage} style={{ marginRight: "3px" }}></img>{" "}
              <div
                style={{
                  fontFamily: "Spoqa Han Sans Neo",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#57676d",
                }}
              >
                URL
              </div>{" "}
            </Card>
          </div>
        </div>
        {currentTabNumber == 0 && (
          <div className="tab-section">
            <div
              className="selected-tab-container"
              onClick={onClickItineraryTab}
            >
              여행
              <div className="selected-blue-line" />
            </div>
            <div className="tab-container" onClick={onClickPostTab}>
              활동
            </div>
          </div>
        )}
        {currentTabNumber == 1 && (
          <div className="tab-section">
            <div className="tab-container" onClick={onClickItineraryTab}>
              여행
            </div>
            <div className="selected-tab-container" onClick={onClickPostTab}>
              활동
              <div className="selected-blue-line" />
            </div>
          </div>
        )}

        <div className="itineraries-section">
          {currentTabNumber === 0 && <ItineraryCard></ItineraryCard>}
          {currentTabNumber === 1 && (
            <InfiniteScroll
              dataLength={posts?.length ?? 0}
              next={fetchMorePosts}
              hasMore={needToLoadMorePosts && currentTabNumber == 1}
              loader={
                <CircularProgress
                  sx={{
                    color: "#35C5f5",
                    marginLeft: "267.5px",
                    marginTop: "30px",
                  }}
                />
              }
              style={{ overflow: "hidden" }}
            >
              {posts.length > 0 && currentTabNumber == 1 ? (
                <Posts posts={posts!} users={users!}></Posts>
              ) : (
                <EmptyItinerary text="등록된 모집글이 없습니다."></EmptyItinerary>
              )}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebDetailProfileContainer;
