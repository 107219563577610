import React from "react";
import { Card, Typography } from "@mui/material";
import ChatImage from "../../assets/ic-profile-message-circle-p-18.svg";
import "./user-bio-container.scss";
import { getAnalytics, logEvent } from "firebase/analytics";

interface RecommendProps {
  user: any;
}

const UserBioContainer: React.FC<RecommendProps> = ({ user }) => {
  const routeDownload = () => {
    logEvent(analytics, 'route_download', { name: 'profile_chat_request'});
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };

  const analytics = getAnalytics();

  return (
    <Card
      className="post-recommandations"
      variant="outlined"
      sx={{
        width: "317px",
        minHeight: "332px",
        boxShadow: "none",
        borderColor: "#e8eced",
        borderWidth: "1px",
        marginBottom: "20px",
      }}
    >
      <Card
        className="chat-button"
        sx={{
          color: "#35c5f5",
          backgroundColor: "rgb(53, 197, 245, 0.12)",
          boxShadow: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          routeDownload();
        }}
      >
        <img src={ChatImage} style={{ marginRight: "10px" }} />
        <div>채팅 요청</div>
      </Card>
      <Typography
        className={"recommandation-header"}
        color="#57676d"
        gutterBottom
        sx={{
          fontFamily: "Spoqa Han Sans Neo",
          fontSize: "18px",
          fontWeight: "500",
          marginTop: "18px",
          marginLeft: "19px",
          marginBottom: "0px",
        }}
      >
        소개
      </Typography>
      <Typography
        className={"recommandation-contents"}
        color="#777f82"
        sx={{
          fontFamily: "Spoqa Han Sans Neo",
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "13px",
          marginLeft: "19px",
          marginBottom: "",
          whiteSpace: "pre-wrap",
          paddingRight: "17px",
        }}
      >
        {user.bio ? <div>{user.bio}</div> : <div>아직 자기소개가 없어요</div>}
      </Typography>
      {/* <Typography
        className={"recommandation-contents"}
        color="#777f82"
        sx={{
          fontFamily: "Spoqa Han Sans Neo",
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "13px",
          marginLeft: "19px",
          marginBottom: "",
        }}
      />
      <div
        style={{
          height: "49px",
          width: "100%",
          backgroundColor: "#f4f5f5",
          position: "absolute",
          bottom: "0",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          fontFamily: "Spoqa Han Sans Neo",
          fontSize: "15px",
          fontWeight: "400",
          color: "#777f82",
          cursor: "pointer",
        }}
      >
        소개글 더보기 {">"}
      </div> */}
    </Card>
  );
};

export default UserBioContainer;
