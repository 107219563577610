import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.scss";
import logo from "../../assets/ic-home-logo-wanderlust-w-22.svg";
import appStoreImage from "../../assets/appstore.svg";
import googlePlayImage from "../../assets/googleplay.svg";

const Footer: React.FC<{}> = () => {
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate("/");
  };

  const handleClickService = () => {
    window.location.replace("https://marco-corp.com/public/terms-of-use.html");
  };

  const handleClickPrivacy = () => {
    window.location.replace(
      "https://marco-corp.com/public/privacy-policy.html"
    );
  };

  return (
    <div className="footer">
      <div className="footer-container" style={{ width: "962px" }}>
        <div className="logo">
          <img
            src={logo}
            onClick={handleClickLogo}
            style={{ marginRight: "32px" }}
          ></img>{" "}
          지금 원더러스트 모바일 앱을 설치해보세요!
          <img
            src={appStoreImage}
            onClick={handleClickLogo}
            style={{ marginLeft: "15px" }}
          ></img>{" "}
          <img
            src={googlePlayImage}
            onClick={handleClickLogo}
            style={{ marginLeft: "10px" }}
          ></img>{" "}
        </div>

        <div>
          <p className="corp">(주) 마르코 </p>
          <p className="privacy">
            사업자 등록 번호: 673-86-01801 | 대표 이준영
          </p>
        </div>

        <div className="privacy" style={{ display: "flex" }}>
          {" "}
          <p
            style={{ marginRight: "36px", cursor: "pointer" }}
            onClick={() => {
              handleClickService();
            }}
          >
            서비스 <br /> 이용약관
          </p>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClickPrivacy();
            }}
          >
            개인정보 <br /> 처리방침
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
