import { MobileWeb, Web } from "../../lib/react-responsive";

const EmptyPost = () => {
  return (
    <div>
      <Web>
        {" "}
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "17px",
            fontWeight: "300",
            color: "#3b3f41",
            width: "575px",
            height: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          동행해요에 설정한 필터에 맞는 검색 결과가 없어요. <br />
          필터를 조정하거나 초기화 해보세요
        </div>
      </Web>
      <MobileWeb>
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "17px",
            fontWeight: "300",
            color: "#3b3f41",
            width: "360px",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          동행해요에 설정한 필터에 맞는 검색 결과가 없어요. <br />
          필터를 조정하거나 초기화 해보세요
        </div>
      </MobileWeb>
    </div>
  );
};

export default EmptyPost;
