import { MobileWeb, Web } from "../../lib/react-responsive";
const EmptyItinerary = (props: { text: string }) => {
  return (
    <div>
      <Web>
        {" "}
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "17px",
            fontWeight: "300",
            color: "#777f82",
            width: "575px",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {props.text}
        </div>
      </Web>
      <MobileWeb>
        <div
          style={{
            fontFamily: "Spoqa Han Sans Neo",
            fontSize: "17px",
            fontWeight: "300",
            color: "#777f82",
            width: "360px",
            height: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {props.text}
        </div>
      </MobileWeb>
    </div>
  );
};

export default EmptyItinerary;
