import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./main.scss";
import Posts from "../../components/posts/posts";
import FilterContainer from "../../components/filter-container/filter-container";
import CityFilterModal from "../../components/city-filter-modal/city-filter-modal";
import PostModel, { Post } from "../../data/models/post";
import UserModel from "../../data/models/user";
import { getPostsDataIncludeUsers } from "../../usecases/get-posts-use-case";
import {
  EventBusMessages,
  CityFilterMessage,
  DateFilterMessage,
  CloseModalMessage,
} from "../../event-bus/event-bus-message";

import useEventBus from "../../event-bus";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress, Divider } from "@mui/material";
import { MobileWeb, Web } from "../../lib/react-responsive";
import MobileFilterContainer from "../../components/filter-container/mobile-filter-container/mobile-filter-container";
import AppLandingContainer from "../../components/app-landing-container/app-landing-container";
import EmptyPost from "../../components/post/empty-post";
import debounce from "lodash/debounce";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Popup from "reactjs-popup";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getPopularPostsDataIncludeUsers } from "../../usecases/get-popular-posts-use-case";
import MobileFilter from "../../components/filter-container/mobile-filter/mobile-filter";
import { json } from "stream/consumers";

const Main: React.FC = () => {
  const { pathname } = useLocation();
  const eventBus = useEventBus<EventBusMessages>();
  const [popularPosts, setPopularPosts] = useState<PostModel[]>();
  const [popularUsers, setPopularUsers] = useState<UserModel[]>();
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [filterCityName, setFilterCityName] = useState<string>("ALL");
  const [filterCityType, setFilterCityType] = useState<any>();
  const [filterStartDate, setFilterStartDate] = useState<string>();
  const [filterEndDate, setFilterEndDate] = useState<string>();
  const [needToLoadMorePosts, setNeedToLoadMorePosts] =
    useState<boolean>(false);
  const [openModal, setopenModal] = useState<boolean>(true);

  const analytics = getAnalytics();

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      syncScroll(0, parseInt(scrollPosition), 5);
      sessionStorage.removeItem("scrollPosition");
    }
  }, [pathname]);

  const syncScroll = useCallback(
    debounce((x: number, y: number, attempt: number) => {
      requestAnimationFrame(() => {
        if (attempt < 1) {
          return;
        }
        const { pageXOffset, pageYOffset } = window;
        if (x !== pageXOffset || y !== pageYOffset) {
          window.scrollTo(x, y);
          syncScroll(x, y, attempt - 1);
        }
      });
    }, 100),
    []
  );

  useEffect(() => {
    window.onunload = window.onbeforeunload = function () {
      window.sessionStorage.clear();
    };

    return () => {
      window.onunload = null;
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe(
      "ResetFilter",
      resetFilterHandler
    );
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("CityFilter", cityFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("CloseModal", closeModal);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  const closeModal = () => {
    setopenModal(false);
  };

  const resetFilterHandler = () => {
    setFilterCityName("");
    setFilterStartDate(undefined);
    setFilterEndDate(undefined);
    window.sessionStorage.clear();
  };

  const cityFilterHandler = (message: CityFilterMessage) => {
    if (message.city === "") {
      setFilterCityName("ALL");
    } else {
      setFilterCityName(message.city);
    }
    setFilterCityType(message.cityType);
    setopenModal(false);
    sessionStorage.setItem("filterCityType", JSON.stringify(message.cityType));
    sessionStorage.setItem("filterCity", JSON.stringify(message.city));
    sessionStorage.setItem("filterCityName", JSON.stringify(message.cityName));
  };

  useEffect(() => {
    const loaderListener = eventBus.subscribe("DateFilter", dateFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  const dateFilterHandler = (message: DateFilterMessage) => {
    if (message.endDate) {
      setFilterStartDate(message.startDate);
      setFilterEndDate(message.endDate);
      sessionStorage.setItem(
        "filterStartDate",
        JSON.stringify(message.startDate)
      );
      sessionStorage.setItem("filterEndDate", JSON.stringify(message.endDate));
    } else {
      setFilterStartDate(message.startDate);
      setFilterEndDate(message.startDate);
      sessionStorage.setItem(
        "filterStartDate",
        JSON.stringify(message.startDate)
      );
      sessionStorage.setItem(
        "filterEndDate",
        JSON.stringify(message.startDate)
      );
    }
  };

  useEffect(() => {
    async function fetchPosts() {
      var data: any;
      var params: any = {};
      params.type = "GATHERING";
      const postData = sessionStorage.getItem("posts");

      if (!postData) {
        // 필터 API 호출
        if (filterCityName) {
          if (filterStartDate != "") {
            params = Object.assign({}, filterCityType);
            params.type = "GATHERING";
            params.startDate = filterStartDate;
            params.endDate = filterEndDate;
            data = await getPostsDataIncludeUsers({
              params,
            });
            const a = [...data[0]];
            setPosts(a);
            sessionStorage.setItem("posts", JSON.stringify(a));
            setUsers(data[1]);
            sessionStorage.setItem("users", JSON.stringify(data[1]));
            if (a.length == 10) {
              setNeedToLoadMorePosts(true);
            } else {
              setNeedToLoadMorePosts(false);
            }
          } else {
            logEvent(analytics, "city_filtered", { cityName: filterCityName });
            params = Object.assign({}, filterCityType);
            params.type = "GATHERING";
            data = await getPostsDataIncludeUsers({
              params,
            });
            const a = [...data[0]];
            setPosts(a);
            sessionStorage.setItem("posts", JSON.stringify(a));
            setUsers(data[1]);
            sessionStorage.setItem("users", JSON.stringify(data[1]));
            if (a.length == 10) {
              setNeedToLoadMorePosts(true);
            } else {
              setNeedToLoadMorePosts(false);
            }
          }
        } else if (filterStartDate) {
          params.startDate = filterStartDate;
          params.endDate = filterEndDate;
          data = await getPostsDataIncludeUsers({
            params,
          });
          const a = [...data[0]];
          setPosts(a);
          sessionStorage.setItem("posts", JSON.stringify(a));
          setUsers(data[1]);
          sessionStorage.setItem("users", JSON.stringify(data[1]));
          if (a.length == 10) {
            setNeedToLoadMorePosts(true);
          } else {
            setNeedToLoadMorePosts(false);
          }
        } else {
          data = await getPostsDataIncludeUsers({
            params,
          });
          const a = [...data[0]];
          setPosts(a);
          sessionStorage.setItem("posts", JSON.stringify(a));
          setUsers(data[1]);
          sessionStorage.setItem("users", JSON.stringify(data[1]));
          if (a.length == 10) {
            setNeedToLoadMorePosts(true);
          } else {
            setNeedToLoadMorePosts(false);
          }
        }
      } else {
        const postData = sessionStorage.getItem("posts");
        if (postData) {
          setopenModal(false);
          setPosts(JSON.parse(postData));
          const filterCityTypeData = sessionStorage.getItem("filterCityType");
          const filterCityNameData = sessionStorage.getItem("filterCity");
          const filterStartDateData = sessionStorage.getItem("filterStartDate");
          const filterEndDateData = sessionStorage.getItem("filterEndDate");
          if (filterCityNameData && filterCityNameData != "") {
            setFilterCityName(JSON.parse(filterCityNameData));
            setFilterCityType(JSON.parse(filterCityTypeData!));
          }
          if (filterStartDateData && filterStartDate != "") {
            setFilterStartDate(JSON.parse(filterStartDateData));
            setFilterEndDate(JSON.parse(filterEndDateData!));
          }
          if (JSON.parse(postData).length % 10 === 0) {
            setNeedToLoadMorePosts(true);
          } else {
            setNeedToLoadMorePosts(false);
          }
          const userData = sessionStorage.getItem("users");
          if (userData) {
            setUsers(JSON.parse(userData));
          }
          // handleScrollPosition();
        } else {
          setopenModal(true);
          data = await getPostsDataIncludeUsers({ params });
          const a = [...data[0]];
          setPosts(a);
          sessionStorage.setItem("posts", JSON.stringify(a));
          setUsers(data[1]);
          sessionStorage.setItem("users", JSON.stringify(data[1]));
          if (a.length == 10) {
            setNeedToLoadMorePosts(true);
          } else {
            setNeedToLoadMorePosts(false);
          }
        }
      }
    }
    fetchPosts();
  }, [filterCityName, filterStartDate]);

  useEffect(() => {
    async function fetchPopularPosts() {
      var data: any;
      var params: any = {};
      const popularPostData = sessionStorage.getItem("popularPosts");
      const popularUserData = sessionStorage.getItem("popularUsers");
      const filterCityNameData = sessionStorage.getItem("popularPostsCityName");

      if (!popularPostData) {
        // 필터 API 호출
        if (filterCityName) {
          params = Object.assign({}, filterCityType);
          data = await getPopularPostsDataIncludeUsers({ params });
          const a = [...data[0]];
          setPopularPosts(a);
          sessionStorage.setItem("popularPosts", JSON.stringify(a));
          setPopularUsers(data[1]);
          sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
          sessionStorage.setItem(
            "popularPostsCityName",
            JSON.stringify(filterCityName)
          );
        } else {
          data = await getPopularPostsDataIncludeUsers({ params });
          const a = [...data[0]];
          setPopularPosts(a);
          sessionStorage.setItem("popularPosts", JSON.stringify(a));
          setPopularUsers(data[1]);
          sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
          sessionStorage.setItem(
            "popularPostsCityName",
            JSON.stringify(filterCityName)
          );
        }
      } else {
        const popularPostData = sessionStorage.getItem("popularPosts");
        if (popularPostData) {
          setPopularPosts(JSON.parse(popularPostData)!);
          const filterCityTypeData = sessionStorage.getItem("filterCityType");
          const filterCityNameData = sessionStorage.getItem("filterCity");
          if (filterCityNameData && filterCityNameData != "") {
            setFilterCityName(JSON.parse(filterCityNameData));
            setFilterCityType(JSON.parse(filterCityTypeData!));
          }
          const popularUserData = sessionStorage.getItem("popularUsers");
          if (popularUserData) {
            setPopularUsers(JSON.parse(popularUserData));
          }
        } else {
          data = await getPopularPostsDataIncludeUsers({ params });
          const a = [...data[0]];
          setPopularPosts(a);
          sessionStorage.setItem("popularPosts", JSON.stringify(a));
          setPopularUsers(data[1]);
          sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
          sessionStorage.setItem(
            "popularPostsCityName",
            JSON.stringify(filterCityName)
          );
        }
      }

      // if (filterCityName) {
      //   data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //     sessionStorage.setItem(
      //       "popularPostsCityName",
      //       JSON.stringify(filterCityName)
      // }

      // if (popularPostData) {
      //   if (filterCityName === JSON.parse(filterCityNameData!)) {
      //     setPopularPosts(JSON.parse(popularPostData!));
      //     setPopularUsers(JSON.parse(popularUserData!));
      //   } else if (filterCityName) {
      //     params = Object.assign({}, filterCityType);
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //     sessionStorage.setItem(
      //       "popularPostsCityName",
      //       JSON.stringify(filterCityName)
      //     );
      //   } else {
      //     sessionStorage.setItem("popularPostsCityName", JSON.stringify("ALL"));
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //   }
      // } else {
      //   if (filterCityName) {
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //     sessionStorage.setItem(
      //       "popularPostsCityName",
      //       JSON.stringify(filterCityName)
      //     );
      //   } else {
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //     sessionStorage.setItem("popularPostsCityName", JSON.stringify("ALL"));
      //   }
      // }

      // if (!filterCityName && filterCityNameData && filterCityName !== "") {
      //   setPopularPosts(JSON.parse(popularPostData!));
      //   setPopularUsers(JSON.parse(popularUserData!));
      // } else {
      //   if (filterCityName === JSON.parse(filterCityNameData!)) {
      //     setPopularPosts(JSON.parse(popularPostData!));
      //     setPopularUsers(JSON.parse(popularUserData!));
      //   } else if (filterCityName) {
      //     params = Object.assign({}, filterCityType);
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //     sessionStorage.setItem(
      //       "popularPostsCityName",
      //       JSON.stringify(filterCityName)
      //     );
      //   } else {
      //     data = await getPopularPostsDataIncludeUsers({ params });
      //     const a = [...data[0]];
      //     setPopularPosts(a);
      //     sessionStorage.setItem("popularPosts", JSON.stringify(a));
      //     setPopularUsers(data[1]);
      //     sessionStorage.setItem("popularUsers", JSON.stringify(data[1]));
      //   }
      // }
    }
    fetchPopularPosts();
  }, [filterCityName]);

  const fetchMoreData = () => {
    var data: any;
    var params: any = {};
    params.type = "GATHERING";
    setTimeout(async () => {
      if (!filterCityName) {
        if (filterStartDate) {
          params.type = "GATHERING";
          params.updatedAt = posts![posts!.length - 1].updatedAt;
          params.startDate = filterStartDate;
          params.endDate = filterEndDate;
          data = await getPostsDataIncludeUsers({
            params,
          });
        } else {
          data = await getPostsDataIncludeUsers({
            params: {
              type: "GATHERING",
              updatedAt: posts![posts!.length - 1].updatedAt,
            },
          });
        }
      } else if (!filterStartDate) {
        params = Object.assign({}, filterCityType);
        params.type = "GATHERING";
        params.updatedAt = posts![posts!.length - 1].updatedAt;
        data = await getPostsDataIncludeUsers({
          params,
        });
      } else {
        params = Object.assign({}, filterCityType);
        params.type = "GATHERING";
        params.updatedAt = posts![posts!.length - 1].updatedAt;
        params.startDate = filterStartDate;
        params.endDate = filterEndDate;
        data = await getPostsDataIncludeUsers({
          params,
        });
      }
      const appendedPosts = posts?.concat(data[0]);
      const appendedUsers = users?.concat(data[1]);
      setPosts(appendedPosts);
      sessionStorage.setItem("posts", JSON.stringify(appendedPosts));
      setUsers(appendedUsers);
      sessionStorage.setItem("users", JSON.stringify(appendedUsers));
      if (data[0].length == 10) {
        setNeedToLoadMorePosts(true);
      } else {
        setNeedToLoadMorePosts(false);
      }
    }, 1500);
  };

  const applyChanges = () => {
    eventBus.publish({
      topic: "DateFilter",
      payload: {
        startDate: "",
        endDate: "",
      },
    });
  };

  return (
    <HelmetProvider>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {" "}
        <Helmet>
          <meta property="og:url" content="https://wanerlust.marco-corp.com/" />
          <meta
            property="og:title"
            content="여행동행이 필요할때 - 원더러스트"
          />
          <meta
            property="og:image"
            content="https://firebasestorage.googleapis.com/v0/b/marco-d2250.appspot.com/o/meta.png?alt=media&token=92ba9f7b-160a-4926-8522-f8417e330cda"
          />
          <meta
            property="og:description"
            content="원더러스트 에서 당신의 잊지 못할 여행을 만들어 줄 동행을 찾아줄게요. 국내여행, 해외여행(유럽여행) 할 때 함께 할 사람을 앱에서 찾아보세요!"
          />
          <meta property="og:image:width" content="1024" />
          <meta property="og:image:height" content="500" />
        </Helmet>
        <div style={{ zIndex: 100 }}>
          <Popup
            open={openModal}
            modal
            position="top center"
            overlayStyle={{ background: "rgba(0, 0, 0, 0.5)" }}
            closeOnDocumentClick={false}
          >
            <CityFilterModal></CityFilterModal>
          </Popup>
        </div>
        <Web>
          <div className="background-image">
            <div className="title-container">동행해요</div>
            <div className="subtitle-container">
              도시와 날짜를 설정하고
              <br />
              지금 바로 원하는 여행 친구를 찾아보세요.
            </div>
          </div>

          <div className="wrapper">
            <div style={{ zIndex: "5" }}>
              <FilterContainer></FilterContainer>
            </div>
            <div className="post-list">
              {!filterStartDate ? (
                <div>
                  {" "}
                  {popularPosts ? (
                    <div>
                      <div className="post-header">인기 동행 모집 글</div>
                      {popularPosts!.length > 0 ? (
                        <Posts
                          posts={popularPosts!}
                          users={popularUsers!}
                        ></Posts>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <EmptyPost></EmptyPost>{" "}
                        </div>
                      )}{" "}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              <InfiniteScroll
                dataLength={posts?.length ?? 0}
                next={fetchMoreData}
                hasMore={needToLoadMorePosts}
                loader={
                  <CircularProgress
                    sx={{
                      color: "#35C5f5",
                      marginLeft: "267.5px",
                      marginTop: "30px",
                    }}
                  />
                }
                style={{ overflow: "hidden" }}
              >
                {posts ? (
                  <div>
                    <div className="post-header" style={{ marginTop: "30px" }}>
                      최신 동행 모집 글
                    </div>
                    {posts.length > 0 ? (
                      <Posts posts={posts!} users={users!}></Posts>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EmptyPost></EmptyPost>{" "}
                      </div>
                    )}{" "}
                  </div>
                ) : (
                  <></>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </Web>
        <MobileWeb>
          <div className="mobile-wrapper">
            <div className="mobile-filter">
              <MobileFilter></MobileFilter>
            </div>
            {/* <Divider
              sx={{
                color: "#e9e9e9",
                overflow: "visible",
                width: "100vw",
                borderTopWidth: "12px",
              }}
            /> */}
            <div className="mobile-post-list">
              {!filterStartDate ? (
                <div>
                  {" "}
                  {popularPosts ? (
                    <div>
                      <div className="mobile-header-container">
                        <div className="mobile-post-header">
                          인기 동행 모집 글
                        </div>
                      </div>
                      {/* <Divider
                        sx={{
                          color: "#e9e9e9",
                          overflow: "visible",
                          width: "100vw",
                          borderTopWidth: "1px",
                        }}
                      /> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        {popularPosts!.length > 0 ? (
                          <Posts
                            posts={popularPosts!}
                            users={popularUsers!}
                          ></Posts>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <EmptyPost></EmptyPost>{" "}
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* <Divider
                sx={{
                  color: "#e9e9e9",
                  overflow: "visible",
                  width: "100vw",
                  borderTopWidth: "6px",
                }}
              /> */}
              <InfiniteScroll
                dataLength={posts?.length ?? 0}
                next={fetchMoreData}
                hasMore={needToLoadMorePosts}
                loader={
                  <CircularProgress
                    sx={{
                      color: "#35C5f5",
                      marginLeft: "160px",
                      marginTop: "30px",
                    }}
                  />
                }
                style={{ overflow: "hidden" }}
              >
                {posts ? (
                  <div>
                    <div className="mobile-header-container">
                      <div className="mobile-post-header">
                        최신 동행 모집 글
                      </div>
                    </div>
                    {/* <Divider
                      sx={{
                        color: "#e9e9e9",
                        overflow: "visible",
                        width: "100vw",
                        borderTopWidth: "1px",
                      }}
                    /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {posts.length > 0 ? (
                        <Posts posts={posts!} users={users!}></Posts>
                      ) : (
                        <EmptyPost></EmptyPost>
                      )}{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </InfiniteScroll>
            </div>
          </div>
          <div className="app-landing">
            <AppLandingContainer line2="동행을 간편하게 구해보세요."></AppLandingContainer>
          </div>
        </MobileWeb>
      </div>
    </HelmetProvider>
  );
};

export default Main;
