import Main from "./pages/main/main";
import NavBar from "./components/nav-bar/nav-bar";
import DetailPost from "./pages/detail-post/detail-post";
import DetailUser from "./pages/detail-user/detail-user";
import DetailItinerary from "./pages/detail-itinerary/detail-itinerary";
import Footer from "./components/footer/footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import { MobileWeb, Web } from "./lib/react-responsive";
import NotFoundPage from "./pages/not-found/not-found";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <HelmetProvider>
      <Web>
        <div style={{ height: "calc(100vh - 300px)" }}>
          <Router>
            <NavBar />
            <div style={{ height: "auto", minHeight: "100%" }}>
              {" "}
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/posts/:postId" element={<DetailPost />} />
                <Route
                  path="/posts/not-found"
                  element={
                    <NotFoundPage errorTitle="요청하신 페이지를 찾을 수 없습니다." />
                  }
                />
                <Route
                  path="/posts/deleted"
                  element={<NotFoundPage errorTitle="삭제된 게시글입니다." />}
                />
                <Route path="/users/:userId" element={<DetailUser />} />
                <Route
                  path="/users/not-found"
                  element={
                    <NotFoundPage errorTitle="유저를 찾을 수 없습니다." />
                  }
                />
                <Route
                  path="/users/deleted-user"
                  element={<NotFoundPage errorTitle="탈퇴한 유저입니다" />}
                />
                <Route
                  path="/users/:userId/itineraries/:itineraryId"
                  element={<DetailItinerary />}
                />
                <Route
                  path="*"
                  element={
                    <NotFoundPage errorTitle="요청하신 페이지를 찾을 수 없습니다." />
                  }
                />
              </Routes>
            </div>

            <Footer />
          </Router>
        </div>
      </Web>
      <MobileWeb>
        {" "}
        <div style={{ backgroundColor: "#f2f4f7" }}>
          <Router>
            <NavBar />
            <div style={{ height: "auto", minHeight: "100%" }}>
              {" "}
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/posts/:postId" element={<DetailPost />} />
                <Route
                  path="/posts/not-found"
                  element={
                    <NotFoundPage errorTitle="요청하신 페이지를 찾을 수 없습니다." />
                  }
                />
                <Route path="/users/:userId" element={<DetailUser />} />
                <Route
                  path="/users/not-found"
                  element={
                    <NotFoundPage errorTitle="유저를 찾을 수 없습니다." />
                  }
                />
                <Route
                  path="/users/deleted-user"
                  element={<NotFoundPage errorTitle="탈퇴한 유저입니다" />}
                />
                <Route
                  path="/users/:userId/itineraries/:itineraryId"
                  element={<DetailItinerary />}
                />
                <Route
                  path="*"
                  element={
                    <NotFoundPage errorTitle="요청하신 페이지를 찾을 수 없습니다." />
                  }
                />
              </Routes>
            </div>
          </Router>
        </div>
      </MobileWeb>
    </HelmetProvider>
  );
}

export default App;
