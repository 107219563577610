import React, { useEffect, useInsertionEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import CityFilter from "./citySelection/city-filter";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import DayPickerRangeControllerWrapper from "../../calendar/DayPickerRangeControllerWrapper";
import "./filter.scss";
import locationImage from "../../../assets/ic-filter-location-pin-g-18.svg";
import filterChevron from "../../../assets/ic-filter-chevron-down-g-28.svg";
import calendarImage from "../../../assets/ic-filter-calendar-g-18.svg";
import whiteLocationImage from "../../../assets/ic-filter-location-pin-w-18.svg";
import whiteFilterChevron from "../../../assets/ic-filter-chevron-down-w-28.svg";
import whiteCalendarImage from "../../../assets/ic-filter-calendar.svg";
import modalLocationImage from "../../../assets/ic-filter-location-pin-g-32.svg";
import mobileModalLocationImage from "../../../assets/ic-filter-location-pin-g-18.svg"
import {
  EventBusMessages,
  DateFilterMessage,
  CityFilterMessage,
} from "../../../event-bus/event-bus-message";

import useEventBus from "../../../event-bus";
import { MobileWeb, Web } from "../../../lib/react-responsive";

const Filter = (props: { type: string }) => {
  const eventBus = useEventBus<EventBusMessages>();
  const [showCitySeleciton, setShowCitySeleciton] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [cityName, setCityName] = useState("도시 선택");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [citySelected, setCitySelected] = useState(false);
  const plans: any = [];

  useEffect(() => {
    const filterCityNameData = sessionStorage.getItem("filterCityName");
    const filterStartDateData = sessionStorage.getItem("filterStartDate");
    const filterEndDateData = sessionStorage.getItem("filterEndDate");
    if (filterCityNameData) {
      setCityName(JSON.parse(filterCityNameData));
      setCitySelected(true);
      if (filterStartDateData) {
        if (filterStartDateData != undefined) {
          setFilterStartDate(JSON.parse(filterStartDateData));
          setFilterEndDate(JSON.parse(filterEndDateData!));
        }
      }
    }
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe(
      "ResetFilter",
      resetFilterHandler
    );
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("DateFilter", dateFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("CityFilter", changeCityName);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  const resetFilterHandler = () => {
    setCityName("도시 선택");
    setFilterStartDate("");
    setFilterEndDate("");
    sessionStorage.setItem("filterCityName", JSON.stringify("도시 선택"));
    sessionStorage.setItem("filterStartDate", JSON.stringify(""));
    sessionStorage.setItem("filterEndDate", JSON.stringify(""));
    setCitySelected(false);
    setShowCitySeleciton(false);
    setShowCalendar(false);
  };

  const dateFilterHandler = (message: DateFilterMessage) => {
    setFilterStartDate(trimDate(message.startDate));
    setFilterEndDate(trimDate(message.endDate ?? ""));
    setShowCalendar(false);
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("popularPosts")
  };

  const onClickshowCitySelection = () => {
    setShowCitySeleciton((current) => !current);
  };

  const onClickShowCalendar = () => {
    setShowCalendar((current) => !current);
  };

  const changeCityName = (message: CityFilterMessage) => {
    sessionStorage.setItem("filterCityName", JSON.stringify(message.cityName));
    setCityName(message.cityName);
    setShowCitySeleciton(false);
    setCitySelected(true);
    sessionStorage.setItem("filterStartDate", JSON.stringify(""));
    sessionStorage.setItem("filterEndDate", JSON.stringify(""));
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("popularPosts")
  };

  function trimDate(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  return (
    <div>
      {props.type == "어디로 여행하시나요?" || props.type == "mobile-modal" ? (    
        <div>
          <Web>
          <div
        className="type-label"
        style={{
          color: "#09090a",
          fontFamily: "Spoqa Han Sans Neo",
          fontWeight: 500,
          fontSize: 40,
          textAlign: "center"
        }}
      >
        <p>어디로 여행하시나요?</p>
      </div>
          </Web>
          <MobileWeb>
          <div
        className="type-label"
        style={{
          color: "#09090a",
          fontFamily: "Spoqa Han Sans Neo",
          fontWeight: 500,
          fontSize: 20,
          marginTop: 20
        }}
      >
        <p>어디로 여행하시나요?</p>
      </div>
          </MobileWeb>
          </div>
) : (      <div
        className="type-label"
        style={{
          color: "#3b3f41",
          fontFamily: "Spoqa Han Sans Neo",
          fontWeight: 400,
          fontSize: 18,
        }}
      >
        <p className="type-label">{props.type}</p>
      </div>)}

      {props.type == "어디로 여행하시나요?" && cityName == "도시 선택" && (
        <Card
          variant="outlined"
          className="modal-filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            borderRadius: "9px",
            zIndex: "3",
          }}
          onClick={() => {
            onClickshowCitySelection();
          }}
        >
          <img className="icon-image" src={modalLocationImage} />
          <div className="modal-label">{cityName}</div>
          <img
            src={filterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
            {props.type == "mobile-modal" && cityName == "도시 선택" && (
        <Card
          variant="outlined"
          className="modal-filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            borderRadius: "9px",
            zIndex: "3",
          }}
          onClick={() => {
            onClickshowCitySelection();
          }}
        >
          <img className="icon-image" src={mobileModalLocationImage} />
          <div className="modal-label">{cityName}</div>
          <img
            src={filterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
      {props.type == "도시" && cityName == "도시 선택" && (
        <Card
          variant="outlined"
          className="filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            borderRadius: "9px",
            zIndex: "3",
          }}
          onClick={() => {
            onClickshowCitySelection();
          }}
        >
          <img className="icon-image" src={locationImage} />
          <div className="label">{cityName}</div>
          <img
            src={filterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
      {props.type == "도시" && cityName != "도시 선택" && (
        <Card
          variant="outlined"
          className="filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            borderRadius: "9px",
            zIndex: "3",
            color: "white",
            backgroundColor: "#777f82",
          }}
          onClick={() => {
            onClickshowCitySelection();
          }}
        >
          <img className="icon-image" src={whiteLocationImage} />
          <div className="label" style={{ color: "white" }}>
            {cityName}
          </div>
          <img
            src={whiteFilterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
      {props.type == "날짜" && !filterStartDate && (
        <Card
          variant="outlined"
          className="filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            zIndex: "1",
            borderRadius: "9px",
            position: "relative",
          }}
          onClick={() => {
            {
              {
                citySelected
                  ? onClickShowCalendar()
                  : alert("도시를 먼저 선택해주세요!");
              }
            }
          }}
        >
          <img className="icon-image" src={calendarImage} />
          <div className="label">날짜 선택</div>
          <img
            src={filterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
      {props.type == "날짜" && filterStartDate && (
        <Card
          variant="outlined"
          className="filter"
          sx={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            borderRadius: "9px",
            zIndex: "3",
            color: "white",
            backgroundColor: "#777f82",
          }}
          onClick={() => {
            onClickShowCalendar();
          }}
        >
          <img className="icon-image" src={whiteCalendarImage} />
          {filterEndDate != "" && (
            <div className="label" style={{ color: "white" }}>
              {filterStartDate} - {filterEndDate}
            </div>
          )}
          {filterEndDate == "" && (
            <div className="label" style={{ color: "white" }}>
              {filterStartDate}
            </div>
          )}
          <img
            src={whiteFilterChevron}
            style={{ position: "absolute", right: "5%" }}
          />
        </Card>
      )}
      <div className="calendar" style={{ marginTop: "7px" }}>
        {showCalendar && <DayPickerRangeControllerWrapper />}
      </div>
      {props.type == "도시" && showCitySeleciton && <CityFilter isModal={false}></CityFilter>}
      {props.type == "어디로 여행하시나요?" && showCitySeleciton && <CityFilter isModal={true}></CityFilter>}
      {props.type == "mobile-modal" && showCitySeleciton && <CityFilter isModal={false}></CityFilter>}
    </div>
  );
};

export default Filter;
