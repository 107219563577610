import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Post from "../post/post";
import PostModel from "../../data/models/post";
import UserModel, { User } from "../../data/models/user";
import { MobileWeb, Web } from "../../lib/react-responsive";
import MobilePost from "../post/mobile-post";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
      // width: "25ch",
    },
  },
  gridList: {
    height: "auto",
    maxWidth: 575,
  },
  card: {
    maxWidth: 160,
    height: "100%",
  },
}));

const Posts = (props: { posts: PostModel[]; users: UserModel[] }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    setPosts(props.posts);
    setUsers(props.users);
  }, [props]);

  function getUser(authorId: string) {
    let owner = users.filter((user) => user.userId == authorId);
    return owner[0];
  }

  function trimDate(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  function getNumberOfDays(startDate: string, endDate: string) {
    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    var DiffInTime = date2.getTime() - date1.getTime();
    var DiffInDays = DiffInTime / (1000 * 3600 * 24);

    return DiffInDays == 0 ? "당일" : DiffInDays + "일";
  }

  function appendParticipants(
    addedParticipants: User[] = [],
    existingParticipants: User[] = [],
    authorId: string
  ) {
    var participants = [...addedParticipants, ...existingParticipants];
    participants = [
      ...new Map(participants.map((item) => [item["userId"], item])).values(),
    ];

    // var set = new Set(participants.map(item => item.userId));
    // participants = Array.from(set);
    participants = [getUser(authorId), ...participants];
    if (participants.length > 3) {
      participants = participants.slice(0, 3);
    }

    return participants;
  }
  function removeDuplicates(array: User[]) {
    var newArray = [];
    newArray = array.filter(function (elem, pos) {
      return array.indexOf(elem) == pos;
    });

    return newArray;
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    navigate("/");
  };

  return (
    <div className={classes.root}>
      {posts.map((post) => (
        <div className={classes.gridList}>
          <Web>
            <Post
              postId={post.postId}
              title={post.title}
              contents={post.contents}
              city={post.city.ko + ", " + post.city.country.ko}
              date={trimDate(post.startDate) + " - " + trimDate(post.endDate)}
              viewCount={post.viewCount}
              requestCount={
                post.contactedUsers ? post.contactedUsers.length : 0
              }
              flag={post.city.country.flag}
              author={getUser(post.authorId)}
              participants={appendParticipants(
                post.addedParticipants,
                post.existingParticipants,
                post.authorId
              )}
            ></Post>
          </Web>
          <MobileWeb>
            <MobilePost
              postId={post.postId}
              title={post.title}
              contents={post.contents}
              city={post.city.ko + ", " + post.city.country.ko}
              date={trimDate(post.startDate) + " - " + trimDate(post.endDate)}
              viewCount={post.viewCount}
              requestCount={
                post.contactedUsers ? post.contactedUsers.length : 0
              }
              flag={post.city.country.flag}
              author={getUser(post.authorId)}
              participants={appendParticipants(
                post.addedParticipants,
                post.existingParticipants,
                post.authorId
              )}
            ></MobilePost>
          </MobileWeb>
        </div>
      ))}
    </div>
  );
};

export default Posts;
