import React, { useEffect, useState } from "react";
import {
  EventBusMessages,
  DateFilterMessage,
  CityFilterMessage,
} from "../../../event-bus/event-bus-message";
import useEventBus from "../../../event-bus";
import "./mobile-date-filter.scss";
import calendarImage from "../../../assets/icFilterDate16.svg";
import xButton from "../../../assets/icFilterXmarkG24.svg";
import DayPickerRangeControllerWrapper from "../../calendar/DayPickerRangeControllerWrapper";

const MobileDateFilter = (props: {}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const eventBus = useEventBus<EventBusMessages>();

  useEffect(() => {
    const filterStartDateData = sessionStorage.getItem("filterStartDate");
    const filterEndDateData = sessionStorage.getItem("filterEndDate");
    if (filterStartDateData) {
      if (filterStartDateData != undefined) {
        setFilterStartDate(trimDate(JSON.parse(filterStartDateData)));
        setFilterEndDate(trimDate(JSON.parse(filterEndDateData!)));
      }
    }
  }, []);

  useEffect(() => {
    const loaderListener = eventBus.subscribe("DateFilter", dateFilterHandler);
    return () => {
      loaderListener.unsubscribe();
    };
  }, []);

  function trimDateToStore(date: string) {
    let result = "";
    result = date.slice(2, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  function trimDate(date: string) {
    let result = "";
    result = date.slice(5, 10);
    result = result.replaceAll("-", ".");
    return result;
  }

  const dateFilterHandler = (message: DateFilterMessage) => {
    setFilterStartDate(trimDate(message.startDate));
    setFilterEndDate(trimDate(message.endDate ?? ""));
    setShowCalendar(false);
    sessionStorage.removeItem("posts");
    sessionStorage.removeItem("popularPosts")
  };

  const onClickShowCalendar = () => {
    setShowCalendar((current) => !current);
  };

  const onClickXButton = () => {
    setFilterStartDate("");
    setFilterEndDate("");
    eventBus.publish({
      topic: "DateFilter",
      payload: {
        startDate: "",
        endDate: "",
      },
    });
  };

  return (
    <div style={{ width: "350px" }}>
      {filterStartDate === "" ? (
        <div>
          {" "}
          <div
            className="date-select-button"
            onClick={() => {
              {
                onClickShowCalendar();
              }
            }}
          >
            <img
              src={calendarImage}
              style={{ width: "16px", margin: "8px" }}
            ></img>
            <div className="date-select-button-text">날짜 선택</div>
          </div>
          <div className="mobile-calendar" style={{ marginTop: "7px" }}>
            {showCalendar && <DayPickerRangeControllerWrapper />}
          </div>
        </div>
      ) : (
        <div>
          <div
            className="selected-date-select-button"
            onClick={() => {
              {
                onClickShowCalendar();
              }
            }}
          >
            <img
              src={calendarImage}
              style={{ width: "16px", margin: "8px" }}
            ></img>
            <div className="date-select-button-text">
              {filterEndDate !== ""
                ? filterStartDate + " - " + filterEndDate
                : filterStartDate + " - " + filterStartDate}
            </div>
            <img
              src={xButton}
              style={{ marginLeft: "10px" }}
              onClick={() => onClickXButton()}
            ></img>
          </div>
          <div className="mobile-calendar" style={{ marginTop: "7px" }}>
            {showCalendar && <DayPickerRangeControllerWrapper />}
          </div>
        </div>
      )}
    </div>
  );
};
export default MobileDateFilter;
