import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./detail-user.scss";

import {
  getDetailProfileData,
  getRecommendedData,
} from "../../usecases/get-detail-user-use-case";

import { getPostsOfUserData } from "../../usecases/get-detail-user-use-case";
import PostModel from "../../data/models/post";
import UserModel from "../../data/models/user";

import UserBioContainer from "../../components/user-bio-container/user-bio-container";
import RecommendContainer from "../../components/recommend-container/recommend-container";
import RecommendUserContainer from "../../components/recommend-container/recommend-user-container";
import WebDetailProfileContainer from "../../components/detail-profile-container/web/web-detail-profile-container";
import { MobileWeb, Web } from "../../lib/react-responsive";
import MobileWebDetailProfileContainer from "../../components/detail-profile-container/mobile-web/mobile-web-profile-container";
import AppLandingContainer from "../../components/app-landing-container/app-landing-container";
import EmptyItinerary from "../../components/itinerary-card/empty-itinerary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAnalytics, logEvent } from "firebase/analytics";

const DetailUser: React.FC = () => {
  const navigate = useNavigate();
  const userId = useParams().userId;
  const [user, setUser] = useState<any>();
  const [latestPosts, setLatestPosts] = useState<any>([]);
  const [recommendedUsers, setRecommendedUsers] = useState<any>([]);
  const [currentTabNumber, setCurrentTabNumber] = useState<number>(0);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [needToLoadMorePosts, setNeedToLoadMorePosts] =
    useState<Boolean>(false);

    const analytics = getAnalytics();

  useEffect(() => {
    setCurrentTabNumber(0);
  }, [users]);

  useEffect(() => {
    async function fetchData() {
      const data = await getDetailProfileData(userId!);
      setUser(data);
      var userInArray = [data];
      setUsers(userInArray);
      window.scrollTo(0, 0);
      if (data === undefined) {
        navigate("/users/not-found");
      } else if (data.active === "DELETED") {
        navigate("/users/deleted-user");
      }
    }

    if (userId) {
      fetchData();
      logEvent(analytics, 'web_screen_view', {
        firebase_screen: 'web_detail_user_page',
        firebase_screen_class: "WebDetailUserPage"
      });
    }
  }, [userId]);

  useEffect(() => {
    async function fetchRecommendation() {
      const data = await getRecommendedData();
      setLatestPosts(data[0]);
      setRecommendedUsers(data[1]);
    }

    if (user) {
      fetchRecommendation();
    }
  }, [user]);

  useEffect(() => {
    async function fetchPosts() {
      const data = await getPostsOfUserData(userId!);
      setPosts(data);
      if (data.length == 10) {
        setNeedToLoadMorePosts(true);
      } else {
        setNeedToLoadMorePosts(false);
      }
    }
    fetchPosts();
  }, [userId]);

  const fetchMorePosts = () => {
    var data: any;
    setTimeout(async () => {
      data = await getPostsOfUserData(
        userId!,
        posts![posts.length - 1].updatedAt
      );
      let appendedItineraries = posts?.concat(data);
      if (data.length == 10) {
        setNeedToLoadMorePosts(true);
      } else {
        setNeedToLoadMorePosts(false);
      }
      setPosts(appendedItineraries);
    }, 1500);
  };

  function onClickItineraryTab() {
    setCurrentTabNumber(0);
  }

  function onClickPostTab() {
    setCurrentTabNumber(1);
  }

  const routeDownload = () => {
    window.location.replace("https://wanderlust.onelink.me/9ag1/ee2rd7u3");
  };

  return (
    <HelmetProvider>
    <div className="detail-post-wrapper">
      {user ? (      <Helmet>
      <meta property="og:url" content="https://wanerlust.marco-corp.com/" />
    <meta property="og:title" content={user.name + "님의 여행 프로필"} />
    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/marco-d2250.appspot.com/o/meta.png?alt=media&token=92ba9f7b-160a-4926-8522-f8417e330cda" />
    <meta property="og:description" content={user.name + "님의 여행일정을 확인하고 원더러스트에서 연락해보세요!"} />
    <meta property="og:image:width" content="1024" />
    <meta property="og:image:height" content="500" />
      </Helmet>) : (<></>)}

      <Web>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          {user ? (
            <WebDetailProfileContainer
              user={user}
              users={users}
              posts={posts}
              fetchMorePosts={fetchMorePosts}
              needToLoadMorePosts={needToLoadMorePosts}
              onClickItineraryTab={onClickItineraryTab}
              onClickPostTab={onClickPostTab}
              currentTabNumber={currentTabNumber}
            />
          ) : (
            <></>
          )}
          <div>
            {user ? <UserBioContainer user={user} /> : <></>}
            <RecommendContainer latestPosts={latestPosts} />
          </div>
        </div>
        <div
          className="user-recommandations"
          style={{ width: "941px", height: "235px" }}
        >
          <RecommendUserContainer recommendedUsers={recommendedUsers} />
        </div>
      </Web>
      <MobileWeb>
        <div className="mobile-post" style={{ width: 360 }}>
          {user ? (
            <MobileWebDetailProfileContainer
              user={user}
              users={users}
              posts={posts}
              fetchMorePosts={fetchMorePosts}
              needToLoadMorePosts={needToLoadMorePosts}
              onClickItineraryTab={onClickItineraryTab}
              onClickPostTab={onClickPostTab}
              currentTabNumber={currentTabNumber}
            />
          ) : (
            <EmptyItinerary text="등록된 모집글이 없습니다."></EmptyItinerary>
          )}
          <div>
            <RecommendContainer latestPosts={latestPosts} />
          </div>
          <div
            className="user-recommandations"
            style={{ width: "360px", height: "235px" }}
          >
            {" "}
            <RecommendUserContainer recommendedUsers={recommendedUsers} />
          </div>
        </div>
        <div className="app-landing">
          <AppLandingContainer line2="나만의 프로필을 공유해보세요!"></AppLandingContainer>
        </div>
      </MobileWeb>
    </div>
    </HelmetProvider>

  );
};

export default DetailUser;
