import React from "react";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import { forbidExtraProps } from "airbnb-prop-types";
import moment from "moment";
import omit from "lodash/omit";

import { DayPickerRangeController, isInclusivelyAfterDay } from "react-dates";

import ScrollableOrientationShape from "./ScrollableOrientationShape";

import { START_DATE, END_DATE, HORIZONTAL_ORIENTATION } from "./constants";

import prevImage from "../../assets/ic-calendar-chevron-left-g-33.png";
import nextImage from "../../assets/ic-navi-chevron-right-b-33.png";
import { Card } from "@mui/material";
import EventBusInstance from "../../event-bus/event-bus-instance";

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocusEndDate: PropTypes.bool,
  initialStartDate: momentPropTypes.momentObj,
  initialEndDate: momentPropTypes.momentObj,
  startDateOffset: PropTypes.func,
  endDateOffset: PropTypes.func,
  showInputs: PropTypes.bool,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,

  keepOpenOnDateSelect: PropTypes.bool,
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,
  daysViolatingMinNightsCanBeClicked: PropTypes.bool,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: ScrollableOrientationShape,
  verticalHeight: PropTypes.number,
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,
  renderMonthElement: PropTypes.func,
  renderMonthText: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,
  renderNavPrevButton: PropTypes.func,
  renderNavNextButton: PropTypes.func,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,
  renderKeyboardShortcutsButton: PropTypes.func,
  renderKeyboardShortcutsPanel: PropTypes.func,

  // i18n
  monthFormat: PropTypes.string,

  isRTL: PropTypes.bool,
});

// const renderCalendarInfo = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         alignContent: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Card
//         variant="outlined"
//         sx={{
//           width: "300px",
//           height: "52px",
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           marginBottom: "10px",
//           backgroundColor: "white",
//           color: "#35c5f5",
//           fontFamily: "Spoqa Han Sans Neo",
//           fontWeight: "500",
//           fontSize: "18px",
//           borderColor: "#35c5f5",
//           borderRadius: "10px",
//           boxShadow: "none",
//           outline: "none",
//           borderWidth: "1px",
//         }}
//       >
//         날짜 선택
//       </Card>
//     </div>
//   );
// };

const defaultProps = {
  // example props for the demo
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  showInputs: false,
  minDate: null,
  maxDate: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: (day) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: undefined,
  isRTL: false,
  renderMonthText: null,
  renderMonthElement: null,
  renderKeyboardShortcutsButton: null,
  renderKeyboardShortcutsPanel: null,

  // navigation related props
  navPrev: (
    <div
      className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5"
      data-dir="prev"
    >
      <img src={prevImage} />
    </div>
  ),
  navNext: (
    <div
      className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5"
      data-dir="next"
    >
      <img src={nextImage} />
    </div>
  ),
  renderNavPrevButton: null,
  renderNavNextButton: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: "YYYY년 M월",
};

class DayPickerRangeControllerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.eventBus = EventBusInstance.eventBus;
    this.state = {
      errorMessage: null,
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      unsavedStartDate: null,
      unsavedEndDate: null,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.applyChanges = this.applyChanges.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    const { daysViolatingMinNightsCanBeClicked, minimumNights } = this.props;
    let doesNotMeetMinNights = false;
    if (daysViolatingMinNightsCanBeClicked && startDate && endDate) {
      const dayDiff = endDate.diff(
        startDate.clone().startOf("day").hour(12),
        "days"
      );
      doesNotMeetMinNights = dayDiff < minimumNights && dayDiff >= 0;
    }
    this.setState({
      startDate,
      endDate: doesNotMeetMinNights ? null : endDate,
      errorMessage: doesNotMeetMinNights
        ? "That day does not meet the minimum nights requirement"
        : null,
    });
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  applyChanges() {
    const startDateString =
      this.state.startDate && this.state.startDate.format("YYYY-MM-DD");
    const endDateString =
      this.state.endDate && this.state.endDate.format("YYYY-MM-DD");
    this.eventBus.publish({
      topic: "DateFilter",
      payload: {
        startDate: startDateString,
        endDate: endDateString,
      },
    });
  }

  render() {
    const { renderCalendarInfo: renderCalendarInfoProp, showInputs } =
      this.props;
    const { errorMessage, focusedInput, startDate, endDate } = this.state;

    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "showInputs",
    ]);

    const startDateString = startDate && startDate.format("YYYY-MM-DD");
    const endDateString = endDate && endDate.format("YYYY-MM-DD");
    const renderCalendarInfo = errorMessage
      ? () => <div>{errorMessage}</div>
      : renderCalendarInfoProp;

    return (
      <div style={{ height: "100%" }}>
        <DayPickerRangeController
          {...props}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          renderCalendarInfo={() => (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  width: "300px",
                  height: "52px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  backgroundColor: "white",
                  color: "#35c5f5",
                  fontFamily: "Spoqa Han Sans Neo",
                  fontWeight: "500",
                  fontSize: "18px",
                  borderColor: "#35c5f5",
                  borderRadius: "10px",
                  boxShadow: "none",
                  outline: "none",
                  borderWidth: "1px",
                }}
                onClick={this.applyChanges}
              >
                날짜 선택
              </Card>
            </div>
          )}
        />
      </div>
    );
  }
}

DayPickerRangeControllerWrapper.propTypes = propTypes;
DayPickerRangeControllerWrapper.defaultProps = defaultProps;

export default DayPickerRangeControllerWrapper;
